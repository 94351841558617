<template>
  <div>
    <div v-if="scnBudget">
      <div v-for="scn in scnBudget.recordset.records" :key="scn.id.value">
        <kbv-budget-indicator-scenario v-if="readyInicators"
          :scenario="scn"
          :indicators="indicators.filter(i => i.access)"
          :base-year="baseYear(scn)"
          :obj-data="objData"
          :key-by-product="keyByProduct()"
          :products="products"
          :key-metric="keyMetric()"
          :key-scenario="keyScn()"
        />
      </div>
    </div>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import KbvBudgetIndicatorScenario from '@/cubus/components/cbs-form/custom-forms/kbv-budget-indicator/KbvBudgetIndicatorScenario.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'KbvBudgetIndicator',
  components: {
    CbsDebug,
    KbvBudgetIndicatorScenario,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      indicators: null,
      objData: null,
      qryIndicators: null,
      objProduct: null,
      products: [],
      objScnBudget: null,
      scnBudget: null,
      objAccess: null,
      objAccessType: null,
      qryAccess: null,
      qryAccessType: null,
      readyInicators: false,
      qryProduct: null,
    }
  },
  setup() {
    const session = useCubus.getSession()
    return {
      session,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadObjData()
      this.loadObjProduct()
      this.loadObjScnBudget()
      this.loadObjAccess()
      this.loadObjAccessType()
      this.loadQryProduct()
      this.loadQryScenario()
      this.loadQryAccessType()
      this.loadQryIndicator()
    },
    baseYear(scn) {
      return scn[this.keyBaseYear()].value || new Date().getFullYear()
    },
    async loadObjData() {
      this.objData = await useCubus.loadObject(this, { objectsid: 'view_indicator_values_current_user' })
    },
    keyByProduct() {
      if (!this.qryIndicators) return null
      // const col = this.qryIndicators.columns.find(c => c.entitysid === 'by_product')
      // return col ? col.key : null
      return 'by_product'
    },
    keyMetric() {
      if (!this.qryIndicators) return null
      // const col = this.qryIndicators.columns.find(c => c.entitysid === 'metric')
      // return col ? col.key : null
      return 'metric'
    },
    async loadObjProduct() {
      this.objProduct = await useCubus.loadObject(this, { objectsid: 'budget_product' })
    },
    async loadQryProduct() {
      this.qryProduct = await useCubus.loadQuery(this, { objectsid: 'budget_product', pageSize: 100000 })
      this.products = this.qryProduct.recordset.records
    },
    async loadObjScnBudget() {
      this.objScnBudget = await useCubus.loadObject(this, { objectsid: 'scenario_budget' })
    },
    async loadQryScenario() {
      this.scnBudget = await useCubus.loadQuery(this, { objectsid: 'scenario_budget', filter: this.filterScnBudget(), pageSize: 100000 })
    },
    filterScnBudget() {
      return {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
          ],
        },
      }
    },
    keyScn() {
      if (this.scnBudget) {
        return this.scnBudget.columns.find(c => c.entitysid === 'scenario').key
      }
      return null
    },
    keyBaseYear() {
      if (this.scnBudget) {
        return this.scnBudget.columns.find(c => c.entitysid === 'baseyear').key
      }
      return null
    },
    async loadObjAccess() {
      this.objAccess = await useCubus.loadObject(this, { objectsid: 'budget_indicator_access_current_user' })
    },
    initQryAccess() {
      if (this.session.user.sid === 'root' || this.session.user.roles.some(r => r.sid === 'admin')) {
        this.setFullAccess()
      } else {
        this.checkAccess()
      }
      this.readyInicators = true
    },
    setFullAccess() {
      this.indicators.forEach(r => {
        if (!r.access) {
          this.$set(r, 'access', 'rw')
        } else {
          r.access = 'rw'
        }
      })
    },
    async checkAccess() {
      this.qryAccess = await useCubus.loadQuery(this, { objectsid: 'budget_indicator_access_current_user', pageSize: 100000 })
      const keyInd = this.qryAccess.columns.find(c => c.entitysid === 'budget_indicator').key
      const keyAccess = this.qryAccess.columns.find(c => c.entitysid === 'indicatoraccess').key
      this.qryAccess.recordset.records.forEach(r => {
        const ind = this.indicators.find(i => i.id.value === r[keyInd].value)
        if (ind) {
          const recAccess = r[keyAccess].value
          if (recAccess !== 0) {
            const recAccessSid = this.qryAccessType.recordset.records.find(a => a.id.value === recAccess).sid.value
            if (!ind.access) {
              this.$set(ind, 'access', recAccessSid)
            } else if (ind.access === 'ro' && recAccessSid === 'rw') {
              ind.access = 'rw'
            }
          }
        }
      })
    },
    async loadObjAccessType() {
      this.objAccessType = await useCubus.loadObject(this, { objectsid: 'accesstype' })
    },
    async loadQryAccessType() {
      this.qryAccessType = await useCubus.loadQuery(this, { objectsid: 'accesstype', pageSize: 100000 })
    },
    async loadQryIndicator() {
      const params = {
        keymode: 'sid',
        objectsid: 'budgetindicator',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'code' },
            },
          ],
        },
        pageSize: 100000,
      }
      this.qryIndicators = await useCubus.loadQuery(this, params)
      this.indicators = this.qryIndicators.recordset.records
      this.initQryAccess()
    },
  },
}
</script>

<style scoped>

</style>
