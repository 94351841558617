<template>
  <div>
    <b-row class="mb-2">
      <b-col v-for="(fld, index) in fields" :key="index" :md="fieldWidth(fld)" class="mb-1">
        <b-form-group :description="fld.descr">
          <div v-if="record[fld.key]">
            <cbs-cell-label v-if="fld.datatype !== 'datetime'" :cell-full="cell(fld)" />

            <b-form-input v-if="fld.datatype === 'id'"
                          :id="fld.key + 'Label'"
                          v-model="record[fld.key].value"
                          placeholder="Place for ID"
                          disabled
            />
            <div v-else-if="fld.entitysid === 'parent'">
              <cbs-reference-picker :record="record" :field="fld" :variant="selectClass(fld)" />
              <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
                {{ fldState(fld).status }}
              </div>
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
              <div class="invalid-feedback">
                {{ fldState(fld).status }}
              </div>
            </div>
            <div v-else-if="fld.datatype === 'int'">
              <b-form-input :id="fld.key + 'Label'"
                            v-model="record[fld.key].value"
                            placeholder="Input integer value"
                            type="number"
                            :formatter="formatter"
                            :state="fldState(fld).state"
                            @change="onChange(fld)"
              />
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
            </div>
            <b-form-input v-else-if="fld.datatype === 'double'"
                          :id="fld.key + 'Label'"
                          v-model="record[fld.key].value"
                          placeholder="Input integer value"
                          type="number"
                          :formatter="formatter"
            />

            <div v-else-if="fld.rendertype === 'jsonfilter'" class="border rounded">
              <cbs-filter :filter="record[fld.key].value"
                          :fields="filterFields()"
                          :param-fields="filterParamFields()"
              />
            </div>
            <div v-else-if="fld.rendertype === 'jsongroupby'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-groupby :groupby="record[fld.key].value"
                           :fields="groupbyFields()"
                           :result-fields="resultGroupbyFields()"
              />
            </div>
            <div v-else-if="fld.rendertype === 'jsonorderby'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-orderby :orderby="record[fld.key].value"
                           :fields="orderbyFields()"
              />
            </div>
            <div v-else-if="fld.rendertype === 'jsonmodification'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-modification-set :value="record[fld.key].value"
                                    :fields="destinationFields()"
                                    :param-fields="filterParamFields()"
              />
            </div>
            <div v-else-if="fld.rendertype === 'jsonfields'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-fields :fieldset="record[fld.key].value" />
            </div>
            <div v-else-if="fld.rendertype === 'jsonreportconfig'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-data-report-config v-if="objectObj" :value="record[fld.key].value" :object="objectObj" :paramRecord="record" />
            </div>
            <div v-else-if="fld.rendertype === 'jsonjoin'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-join-set :value="record[fld.key].value" />
            </div>
            <div v-else-if="fld.rendertype === 'jsonargument'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-argument v-if="recEntity" :value="record[fld.key].value" :entity="recEntity" />
              <div v-else>Entity is not selected.</div>
            </div>
            <div v-else-if="fld.rendertype === 'jsonwidgetparam'" class="border rounded border-secondary p-1">
              <cbs-widget-param :value="record[fld.key].value" />
            </div>
            <div v-else-if="fld.rendertype === 'jsonfunction'" class="border rounded border-secondary p-1">
              <cbs-function :value="record[fld.key].value" />
            </div>

            <cbs-json-editor v-else-if="fld.datatype === 'json'" :value="record[fld.key].value" />

            <div v-else-if="fld.datatype === 'text'">
              <b-form-textarea
                :id="fld.key + 'Label'"
                v-model="record[fld.key].value"
                placeholder="Textarea"
                rows="3"
                :state="fldState(fld).state"
                @change="onChange(fld)"
              />
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
            </div>

            <b-form-checkbox v-else-if="fld.datatype === 'bit'"
                             v-model="record[fld.key].value"
            />
            <div v-else-if="fld.datatype === 'ref'">
              <cbs-reference-picker :record="record" :field="fld" :variant="selectClass(fld)" @updateCell="onRefUpdate(fld)" />
              <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
                {{ fldState(fld).status }}
              </div>
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
              <div class="invalid-feedback">
                {{ fldState(fld).status }}
              </div>
            </div>
            <div v-else-if="fld.datatype === 'refsid'">
              <cbs-refsid-picker :record="record" :field="fld" :variant="selectClass(fld)" />
              <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
                {{ fldState(fld).status }}
              </div>
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
              <div class="invalid-feedback">
                {{ fldState(fld).status }}
              </div>
            </div>
            <div v-else-if="fld.datatype === 'file'">
              <cbs-file-entity :field="fld"
                               :cell="record[fld.key]"
                               :initcell="compare[fld.key]"
                               :can-download="canDownload()"
                               :objectid="object.object.id"
              />
            </div>
            <div v-else-if="fld.datatype === 'datetime'">
              <cbs-card-date-picker :cell-full="cell(fld)" :cell="record[fld.key]" :state="fldState(fld)" @change="onChange(fld)" />
              <cbs-period-picker v-if="isPeriod() && fld.entitysid === 'finish'"
                                 :start="cell(field('start'))"
                                 :finish="cell(field('finish'))"
              />
            </div>

            <div v-else>
              <b-form-input :id="fld.key + 'Label'"
                            v-model="record[fld.key].value"
                            placeholder="Normal Input"
                            :state="fldState(fld).state"
                            @change="onChange(fld)"
              />
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
            </div>

            <div v-if="fld.isunique === true" style="margin-top:5px;">
              <b-button v-if="false"
                        variant="flat-primary" size="sm"
                        :disabled="isEmpty(fld,record[fld.key])"
                        @click="checkUnique(fld,record[fld.key])"
              >
                <feather-icon icon="EyeIcon" /> Check unique
              </b-button>
            </div>

            <div v-if="isDebug">
              <p>Field: {{ field(fld.key) }}</p>
              <p>Cell: {{ record[fld.key] }}</p>
              <p>State: {{ fldState(fld) }}</p>
              <p>Cell Status: {{ cellStatus(fld) }}</p>
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="object">
      <div v-if="object.object.sid === 'mountstep'">
        <cbs-data-object v-if="record && record.sourceobjectid && record.sourceobjectid.value"
                         :hide="true"
                         :objectid="record.sourceobjectid.value"
                         @load="filterSrcObjReady"
        />
        <cbs-data-object v-if="record && record.destobjectid && record.destobjectid.value"
                         :hide="true"
                         :objectid="record.destobjectid.value"
                         @load="filterDestObjReady"
        />
        <cbs-data-object v-if="record && record.objectid && record.objectid.value"
                         :hide="true"
                         :objectid="record.objectid.value"
                         @load="filterParamObjReady"
        />
        <cbs-data-object hide objectsid="mountoperand" @load="loadMountOperObj" />
        <cbs-data-record v-if="mountOperObj && record.mountoperid && record.mountoperid.value"
                         :object-full="mountOperObj" hide :rowid="record.mountoperid.value" @load="loadMountOperRec"
        />
      </div>
      <div v-if="object.object.sid === 'scalar'">
        <cbs-data-object v-if="record && record.sourceobjectid && record.sourceobjectid.value"
                         :hide="true"
                         :objectid="record.sourceobjectid.value"
                         @load="filterSrcObjReady"
        />
        <cbs-data-reference entitysid="entity" @load="onLoadEntityRef" />
      </div>
      <div v-if="object.object.sid === 'cbsjoin'">
        <cbs-data-object v-if="record && record.sourceobjectid && record.sourceobjectid.value"
                         :hide="true"
                         :objectid="record.sourceobjectid.value"
                         @load="filterSrcObjReady"
        />
      </div>
      <div v-if="object.object.sid === 'cbsviewconfig'">
        <cbs-data-object objectsid="object" hide @load="onLoadObjectObj" />
        <cbs-data-object v-if="record.objectid.value" :objectid="record.objectid.value" hide @load="onLoadRecordObj" />
        <cbs-data-record v-if="objectObj && record && record.objectid && record.objectid.value" :object-full="objectObj" :rowid="record.objectid.value" hide @load="onLoadObjRec" />
        <cbs-data-object v-if="objectRecord" hide :objectid="objectRecord.record.refobjectid.value" @load="onLoadRefObj" />
        <cbs-data-reference entitysid="entity" @load="onLoadEntityRef" />
      </div>
      <div v-if="object.object.sid === 'reportconfig'">
        <cbs-data-object :objectid="record.objectid.value" hide @load="onLoadObjectObj" />
      </div>
      <div v-if="object.object.sid === 'entityparam'">
        <cbs-data-entity v-if="record && record.entityid && record.entityid.value"
                         :entityid="record.entityid.value"
                         @load="onLoadEntity"
        />
      </div>
      <div v-if="object.object.sid === 'cbsattributeparam'">
        <cbs-data-attribute v-if="record && record.attributeid && record.attributeid.value"
                            :attribute-id="record.attributeid.value"
                            @load="onLoadAttribute"
        />
        <cbs-data-entity v-if="recAttribute && recAttribute.entity && recAttribute.entity.id"
                         :entityid="recAttribute.entity.id"
                         @load="onLoadEntity"
        />
        <cbs-data-object v-if="recAttribute" :objectid="recAttribute.objectid" hide @load="onLoadRecAttrObj" />
      </div>
      <div v-if="object.object.sid === 'objectparam'">
        <cbs-data-object v-if="record && record.objectid && record.objectid.value"
                         :hide="true"
                         :objectid="record.objectid.value"
                         @load="filterParamObjReady"
        />
        <cbs-data-object v-if="filterParamObj && filterParamObj.object.meta === 'import'"
                         :hide="true"
                         :objectid="filterParamObj.object.refobjectid"
                         @load="object_refObject_ready"
        />
      </div>
      <div v-if="object.object.sid === 'cbsobjectrolefilter'">
        <cbs-data-object v-if="record && record.objectid && record.objectid.value"
                         :hide="true"
                         :objectid="record.objectid.value"
                         @load="onLoadObjectObj"
        />
      </div>
      <div v-else-if="object.object.sid === 'index'">
        <cbs-data-object v-if="record && record.objectid && record.objectid.value"
                         :hide="true"
                         :objectid="record.objectid.value"
                         @load="onLoadObjectObj"
        />
      </div>
    </div>

    <cbs-debug v-if="isRoot && !hide" :context="this" />
  </div>
</template>

<script>
import {
  BCol, BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BRow,
} from 'bootstrap-vue'
import CbsReferencePicker from '@/cubus/components/reference/CbsReferencePicker.vue'
import CbsCellLabel from '@/cubus/components/object/CbsCellLabel.vue'
import CbsCardDatePicker from '@/cubus/components/date-picker/cbsCardDatePicker.vue'
import CbsPeriodPicker from '@/cubus/components/period-picker/CbsPeriodPicker.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsFilter from '@/cubus/components/filter/CbsFilter.vue'
import CbsGroupby from '@/cubus/components/groupby/CbsGroupby.vue'
import CbsOrderby from '@/cubus/components/orderby/CbsOrderby.vue'
import CbsModificationSet from '@/cubus/components/modification/CbsModificationSet.vue'
import CbsFileEntity from '@/cubus/components/file/CbsFileEntity.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataRecord from '@/cubus/components/query/CbsDataRecord.vue'
import CbsFields from '@/cubus/components/fields/CbsFields.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataReportConfig from '@/cubus/components/object-report/CbsDataReportConfig.vue'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsJoinSet from '@/cubus/components/join/CbsJoinSet.vue'
import CbsArgument from '@/cubus/components/argument/CbsArgument.vue'
import CbsDataEntity from '@/cubus/components/entity/CbsDataEntity.vue'
import CbsDataAttribute from '@/cubus/components/attribute/CbsDataAttribute.vue'
import CbsWidgetParam from '@/cubus/components/dashboard/CbsWidgetParam.vue'
import CbsJsonEditor from '@/cubus/components/json-editor/CbsJsonEditor.vue'
import CbsRefsidPicker from '@/cubus/components/reference-sid/CbsRefsidPicker.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import CbsFunction from '@/cubus/components/funciton/CbsFunction.vue'

export default {
  name: 'CbsRecordForm',
  components: {
    CbsJsonEditor,
    CbsWidgetParam,
    CbsDataAttribute,
    CbsDataEntity,
    CbsArgument,
    CbsDataReference,
    CbsDataReportConfig,
    CbsDebug,
    CbsReferencePicker,
    CbsRefsidPicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    CbsCellLabel,
    CbsCardDatePicker,
    CbsPeriodPicker,
    CbsFilter,
    CbsGroupby,
    CbsOrderby,
    CbsModificationSet,
    CbsFileEntity,
    CbsDataObject,
    CbsDataRecord,
    CbsFields,
    CbsJoinSet,
    CbsFunction,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    record: {
      type: Object,
      default: null,
    },
    compare: {
      type: Object,
      default: null,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  data() {
    return {
      isDebug: false,
      refs: {},
      filterSrcObj: null,
      filterDestObj: null,
      filterParamObj: null,
      mountOperObj: null,
      mountOperRec: null,
      viewObj: null,
      objectObj: null,
      objectRecord: null,
      refObj: null,
      isValid: false,
      entityRef: [],
      recEntity: null,
      recAttribute: null,
      recAttrObject: null,
      recordObject: null,
      object_refObject: null,
    }
  },
  created() {
    this.init()
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
      isRoot: useCubus.isRoot(),
    }
  },
  computed: {},
  methods: {
    init() {
      // console.log('CbsRecordForm start')
      // this.checkRecordValid()
      if (this.object.object.sid === 'mountstep') {
        this.loadRef_entity()
      }
    },
    async loadRef_entity() {
      this.entityRef = await useCubus.loadRef(this, { entitysid: 'entity' })
    },
    fieldWidth(fld) {
      if (fld.datatype === 'json') { return 12 }
      return 6
    },
    cell(fld) {
      return {
        field: fld,
        cell: this.record[fld.key],
        state: this.cellState(fld),
      }
    },
    cellState(fld) {
      return {
        check: this.fldState(fld).state,
        status: this.fldState(fld).status,
        change: this.cellStatus(fld),
      }
    },
    fldState(fld) {
      let stat = { state: null, status: '' }
      const isEmpty = this.isCellEmpty(fld)
      if (fld.isrequired) {
        if (isEmpty) {
          stat = { state: false, status: 'Attribute is required.' }
        } else if (this.isCellChanged(fld)) {
          stat = { state: true, status: '' }
        }
      }
      if (fld.isunique && !isEmpty) {
        if (!this.isCellChanged(fld)) {
          stat = { state: null, status: '' }
        } else if (this.record[fld.key].isUnique === true) {
          stat = { state: true, status: '' }
        } else if (this.record[fld.key].isUnique === false) {
          stat = { state: false, status: 'Value is not unique.' }
        } else {
          stat = { state: false, status: 'Unique check is required.' }
        }
      }
      return stat
    },
    isCellEmpty(fld) {
      const cell = this.record[fld.key]
      return cell.value === null || cell.value === '' || (fld.datatype === 'ref' && cell.value === 0)
    },
    isCellChanged(fld) {
      return this.cellStatus(fld) === 'changed'
    },
    cellStatus(field) {
      const cell = this.record[field.key]
      const initCell = this.compare[field.key]
      if (cell) {
        if (field.rendertype === 'jsonfilter') {
          return (`${JSON.stringify(cell.value)}` === `${JSON.stringify(initCell.value)}` || (!cell.value && !initCell.value)) ? 'init' : 'changed'
        }
        if (field.datatype === 'json') {
          return (`${JSON.stringify(cell.value)}` === `${JSON.stringify(initCell.value)}`) ? 'init' : 'changed'
        }
        return (`${cell.value}` === `${initCell.value}` || (!cell.value && !initCell.value)) ? 'init' : 'changed'
      }
      return 'init'
    },
    selectClass(fld) {
      if (this.fldState(fld).state === null) return null
      if (this.fldState(fld).state === true) return 'border rounded border-success'
      return 'border rounded border-danger'
    },
    onInputVSelect(val, index) {
      if (!val) this.record[this.fields[index].key] = { value: 0, title: '' }
    },
    filterBy(option, label, search) {
      const pattern = `.*${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    isPeriod() {
      return this.record.start && this.record.finish
    },
    field(key) {
      return this.fields.find(f => f.key === key)
    },
    checkUnique(fld, cell) {
      // console.log('checkUnique', fld, cell)
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'checkunique',
          param: {
            objectid: this.object.object.id,
            entityid: fld.entityid,
            value: cell.value.toString(),
          },
        },
      })
        .then(response => {
          console.log('check unique response', response)
          if (response.data) {
            if (response.data.thread) this.loadCheckUnique(response.data.thread, cell)
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
          }
        })
        .catch(error => {
          console.log('check unique error', error)
        })
    },
    loadCheckUnique(threadName, cell) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          // console.log('loadCheckUnique', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                this.$set(cell, 'isUnique', response.data.thread.result.record.isUnique)
              } else {
                this.delayCheckUnique(threadName, cell)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    delayCheckUnique(threadName, cell) { setTimeout(() => this.loadCheckUnique(threadName, cell), 500) },
    onChange(fld) {
      console.log('onChange', fld)
      if (fld.isunique) {
        this.checkUnique(fld, this.record[fld.key])
      }
      this.checkRecordValid()
    },
    destinationFields() {
      if (this.filterDestObj) {
        return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
      }
      if (this.recAttrObject) {
        return this.recAttrObject.attributes.map(a => useCubus.attributeToField(a))
      }
      // return []
      return this.entityRef
    },
    canDownload() {
      return this.object.object && this.object.object.access
          && (this.object.object.access.download === 'grant' || this.object.object.access.download === 'withgrant')
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    isEmpty(fld, cell) {
      return cell.value === null || cell.value === '' || (fld.datatype === 'ref' && cell.value === 0)
    },
    filterFields() {
      if (this.object.object.sid === 'mountstep') {
        if (this.mountOperRec) {
          if (this.mountOperRec.record.sid.value === 'insert') {
            if (this.filterSrcObj) {
              return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
            }
            return []
          }
          if (this.filterDestObj) {
            return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
          }
          // return []
          return this.entityRef
        }
        // return []
        return this.entityRef
      }
      if (this.object.object.sid === 'scalar') {
        if (this.filterSrcObj) {
          return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.object.object.sid === 'cbsjoin') {
        if (this.filterSrcObj) {
          return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.object.object.sid === 'cbsviewconfig') {
        if (this.refObj) {
          return this.refObj.attributes.map(a => useCubus.attributeToField(a))
        }
      }
      if (this.object.object.sid === 'objectparam') {
        if (this.filterParamObj && this.filterParamObj.object.meta === 'import' && this.object_refObject) {
          return this.object_refObject.attributes.map(a => useCubus.attributeToField(a))
        }
      }
      if (this.object.object.sid === 'cbsobjectrolefilter') {
        if (this.objectObj) {
          return this.objectObj.attributes.map(a => useCubus.attributeToField(a))
        }
      }
      if (this.object.object.sid === 'index') {
        if (this.objectObj) {
          return this.objectObj.attributes.map(a => useCubus.attributeToField(a))
        }
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    orderbyFields() {
      if (this.object.object.sid === 'cbsviewconfig' && this.refObj) {
        return this.recordObject.attributes.map(a => useCubus.attributeToField(a))
      }
      return this.filterFields()
    },
    groupbyFields() {
      if (this.object.object.sid === 'mountstep') {
        if (this.filterDestObj) {
          return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.object.object.sid === 'cbsviewconfig') {
        if (this.refObj) {
          return this.refObj.attributes.map(f => useCubus.attributeToField(f))
        }
        return []
      }
      if (this.filterSrcObj) {
        return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    resultGroupbyFields() {
      if (this.object.object.sid === 'mountstep') {
        if (this.filterDestObj) {
          return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.object.object.sid === 'cbsviewconfig') {
        if (this.recordObject) {
          return this.recordObject.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.filterSrcObj) {
        return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    filterParamFields() {
      if (this.object.object.sid === 'mountstep') {
        if (this.filterParamObj) {
          return this.filterParamObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.object.object.sid === 'cbsviewconfig') {
        if (this.entityRef) {
          return this.entityRef
        }
        return []
      }
      if (this.object.object.sid === 'scalar') {
        if (this.entityRef) {
          return this.entityRef
        }
        return []
      }
      if (this.object.object.sid === 'objectparam') {
        if (this.filterParamObj) {
          return this.filterParamObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.object.object.sid === 'cbsobjectrolefilter') {
        if (this.objectObj) {
          return this.objectObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    formatter(value) {
      return Number(value)
    },
    filterSrcObjReady(obj) {
      this.filterSrcObj = obj
    },
    filterDestObjReady(obj) {
      this.filterDestObj = obj
    },
    filterParamObjReady(obj) {
      this.filterParamObj = obj
    },
    loadMountOperObj(obj) {
      this.mountOperObj = obj
    },
    loadMountOperRec(rec) {
      this.mountOperRec = rec
    },
    onLoadObjectObj(obj) {
      this.objectObj = obj
    },
    onLoadViewObj(obj) {
      this.viewObj = obj
    },
    onLoadObjRec(rec) {
      this.objectRecord = rec
    },
    onLoadRefObj(obj) {
      this.refObj = obj
    },
    onLoadRecordObj(obj) {
      this.recordObject = obj
    },
    checkRecordValid() {
      this.record.isValid = this.fields.find(fld => !this.isFieldValid(fld)) === undefined
    },
    isFieldValid(fld) {
      return !(fld.isrequired && this.isCellEmpty(fld))
    },
    onLoadEntityRef(ref) {
      this.entityRef = ref
    },
    onLoadEntity(ent) {
      this.recEntity = ent
    },
    onLoadAttribute(attr) {
      this.recAttribute = attr
    },
    object_refObject_ready(obj) {
      this.object_refObject = obj
    },
    onLoadRecAttrObj(obj) { this.recAttrObject = obj },
    onRefUpdate(fld) {
      // console.log('onRefUpdate.fld', fld)
      // console.log('onRefUpdate.cell', this.record[fld.key])
      if (fld.isunique) {
        this.checkUnique(fld, this.record[fld.key])
      }
    },
  },
}
</script>

<style scoped>

</style>
