<template>
  <div>
    <div style="margin-bottom: 1rem; margin-left: 1rem;">
      <table>
        <tbody>
          <tr>
            <td v-if="true" style="padding-right: 2rem;">
              <b-button variant="danger" size="sm" @click="mountPaycal()">
                <feather-icon icon="RefreshCwIcon"/> Сброс ПК
              </b-button>
            </td>
            <td>Курсы валют:</td>
            <td style="padding: 0.2rem 0.5rem 0.2rem 1rem;">USD:</td>
            <td class="border-secondary" style="padding: 0.2rem 1rem 0.2rem 1rem; min-width: 5rem;">
              <cbs-budreq-cell v-if="qryRate"
                               :record="getRateRecord('USD')"
                               key-value="currency_rate"
                               value-type="number"
                               access="rw"
                               style="text-align: center; min-width: 5rem;"
                               no-next-cell
                               @edit="onEditRate(getRateRecord('USD'), $event)"/>
            </td>
            <td style="padding: 0.2rem 0.5rem 0.2rem 1rem;">EUR:</td>
            <td class="border-secondary" style="padding: 0.2rem 1rem 0.2rem 1rem; min-width: 5rem;">
              <cbs-budreq-cell v-if="qryRate"
                               :record="getRateRecord('EUR')"
                               key-value="currency_rate"
                               value-type="number"
                               access="rw"
                               style="text-align: center; min-width: 5rem;"
                               no-next-cell
                               @edit="onEditRate(getRateRecord('EUR'), $event)"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="dataReady" style="overflow: scroll; max-height: 70vh; min-height: 20rem;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th rowspan="2" class="corner1" :id="`corner1_${uuid}`">Код</th>
            <th rowspan="2" class="corner2" :id="`corner2_${uuid}`" :style="styleStickCol2()">Наименование</th>
            <th class="th-row1">Контрагент</th>
            <th class="th-row1">Подразделение</th>
            <th class="th-row1">Валюта</th>
            <th colspan="3" class="th-row1" style="text-align: center;" :id="`th_stick_row_${uuid}`">{{ weekTitle(1).start }}<br>{{ weekTitle(1).finish }}</th>
            <th v-for="n in [2,3,4,5,6]" :key="n" colspan="3" class="th-row1" style="text-align: center;">{{ weekTitle(n).start }}<br>{{ weekTitle(n).finish }}</th>
          </tr>
          <tr>
            <th class="flt0 th-row2">
              <cbs-budreq-filter :column="getColumn('firm')" :reference="refFirm()" :condition="getCondition('firm')" @apply="applyFilter()" />
            </th>
            <th class="flt0 th-row2">
              <cbs-budreq-filter :column="getColumn('business_unit')" :reference="refUnit()" :condition="getCondition('business_unit')" @apply="applyFilter()" />
            </th>
            <th class="flt0 th-row2">
              <cbs-budreq-filter :column="getColumn('currency')" :reference="refCur()" :condition="getCondition('currency')" @apply="applyFilter()" />
            </th>
            <th v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="th-row2">{{ crs.curName }}</th>
          </tr>
        </thead>
        <tbody v-for="item in paycalItems()" :key="item.id.value">
          <tr>
            <td class="cbs-cell stick-col1 table-light">
              <div style="display: flex;" :style="'margin-left: ' + (item.parentlevel.value - 1) * 0.75 + 'rem;'">
                <span v-if="paycalType(item) === 'folder'" style="cursor: pointer;" @click="expandPaycal(item)" @dblclick="expandAllPaycal(item)">
                  <feather-icon size="16" :icon="item.cbs_expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" />
                </span>
                <span v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" style="cursor: pointer;" @click="expandLines(item)">
                  <feather-icon size="16" :icon="item.cbs_expanded ? 'MinusCircleIcon' : 'PlusCircleIcon'" />
                </span>
                &nbsp;&nbsp;{{ item.code.value }}
              </div>
            </td>
            <td class="cbs-cell stick-col2 table-light" :style="styleStickCol2()">
              <div :style="'margin-left: ' + (item.parentlevel.value - 1) * 1 + 'rem;'">
                {{ item.name.value }}&nbsp;
                <b-badge v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" pill
                         variant="light-secondary" title="Количество заявок"
                >
                  {{ requestLinesCount(item) }}
                </b-badge>&nbsp;
                <b-badge v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" pill variant="light-success"
                         title="Добавить строку" style="cursor: pointer;" @click="addLine(item)">+</b-badge>
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="cell-num"
                :class="(item.sid.value === 'saldo_out' || item.sid.value === 'saldo_in') && getTotalAmount(item, crs.periodNum, crs.curName) < 0 ? 'bg-danger text-light' : ''"
            >
              <div>{{ amountToStr(getTotalAmount(item, crs.periodNum, crs.curName)) }}</div>
            </td>
          </tr>
          <tr v-for="line in requestLines(item)" :key="line.id.value">
            <td class="cbs-cell stick-col1 table-light" style="text-align: right;">{{ line.cash_request.value }}</td>
            <td class="cbs-cell stick-col2 table-light" :style="styleStickCol2()">
              <span>
                {{ line.descr.value }}
                <b-badge v-if="line.cash_request_sid.value.startsWith('dummy_')" pill variant="light-danger"
                         title="Удалить" style="cursor: pointer;" @click="deleteLine(line)">
                  <feather-icon icon="TrashIcon" />
                </b-badge>
              </span>
            </td>
            <td class="cbs-cell">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'firm')"
                                     :prop-ref="refFirm()" @updateCell="onUpdateCell(line, 'firm', $event)"
                                     :key-mode="'sid'"
              />
              <span v-else>{{ line.firm.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'business_unit')"
                                     :prop-ref="refUnit()" @updateCell="onUpdateCell(line, 'business_unit', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
              <span v-else>{{ line.business_unit.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'currency')"
                                     :prop-ref="refCur()" @updateCell="onUpdateCell(line, 'currency', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
              <span v-else>{{ line.currency.title }}</span>
            </td>
            <td v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="cbs-cell" :style="styleAmountCell(item, line, crs)">
              <cbs-budreq-cell v-if="line.currency.title === crs.curName"
                               :record="getRecord(line, crs.periodNum, crs.curName)"
                               key-value="curamount"
                               value-type="number"
                               :access="item.sid.value === 'saldo_out' || (item.sid.value === 'saldo_in' && crs.periodNum > 1) ? 'ro' : 'rw'"
                               style="text-align: right;"
                               @edit="onEdit(getRecord(line, crs.periodNum, crs.curName), $event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal :ref="'modal_mount_' + uuid"
             cancel-variant="outline-warning"
             ok-title="Подтверждаю"
             cancel-title="Отмена"
             centered
             title="Подтверждение"
             @ok="runMount"
             @cancel="onCancelMount"
             @close="onCloseMount"
    >
      Инициирован сброс Платежного календаря.<br>Все данные кроме Запросов наличности будут удалены.<br>Вы подтверждаете?
    </b-modal>
    <cbs-debug v-if="isRoot" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import {
  BBadge, BButton,
} from 'bootstrap-vue'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'
import CbsBudreqFilter from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqFilter.vue'

export default {
  name: 'CbsPaycalTable',
  components: {
    CbsBudreqFilter,
    CbsReferencePicker3,
    CbsBudreqCell,
    CbsDebug,
    BBadge,
    BButton,
  },
  props: {
    period: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      objCashreq: null,
      qryMethod: null,
      qryRefPaycal: null,
      qryCashreq: null,
      uuid: null,
      dataReady: false,
      corner1: null,
      thStickRow: null,
      qryPaycal: null,
      qryScenario: null,
      startOfMonth: null,
      periodNums: [1, 2, 3, 4, 5, 6],
      curs: ['KZT', 'USD', 'EUR'],
      cursor: null,
      counter: 0,
      qryCfDirection: null,
      qryCurrency: null,
      qryFirm: null,
      qryUnit: null,
      qryRate: null,
      condDate: null,
      condScenario: null,
      filter: null,
    }
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
      condNotDel: useCubus.cond_notDel(),
    }
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.initParams()
      this.initData()
    },
    initParams() {
      const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      this.startDate = new Date(year, month - 1, day)
      this.startOfMonth = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1)
      this.cursor = this.getCursor()
      this.condDate = {
        isactive: true,
        oper: { sid: 'notless' },
        args: [
          { type: { sid: 'entitysid' }, value: 'date' },
          { type: { sid: 'value' }, value: this.dateToUTCString(this.startOfMonth) },
        ],
      }
    },
    async initData() {
      this.qryScenario = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'scenario',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'sid' },
                  { type: { sid: 'value' }, value: 'paycal' },
                ],
              },
            ],
          },
        },
        pageSize: 100000,
      })
      this.condScenario = {
        isactive: true,
        oper: { sid: 'equal' },
        args: [
          { type: { sid: 'entitysid' }, value: 'scenario' },
          { type: { sid: 'value' }, value: this.qryScenario.recordset.records[0].id.value },
        ],
      }
      this.filter = {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            this.condNotDel,
            this.condDate,
            this.condScenario,
          ],
        },
      }
      this.objCashreq = await useCubus.loadObject(this, {
        objectsid: 'cash_request',
      })
      this.qryMethod = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'paycal_enter_method',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
      this.qryRefPaycal = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'ref_paycal',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              entity: { sid: 'code' },
              oper: { sid: 'asc' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.qryCfDirection = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'ref_cf_direction',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
      this.qryCurrency = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'currency',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
      this.qryFirm = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'reffirm',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              entity: { sid: 'name' },
              oper: { sid: 'asc' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.qryUnit = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'business_unit',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              entity: { sid: 'name' },
              oper: { sid: 'asc' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.qryRate = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'cube_paycal_rate',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
              this.condDate,
              this.condScenario,
            ],
          },
        },
        pageSize: 100000,
      })
      await this.loadQryPaycal()
    },
    async loadQryPaycal() {
      this.qryPaycal = await useCubus.loadQuery(this, {
        keymode: 'sid',
        withsubrecords: true,
        objectsid: 'cube_paycal',
        filter: this.filter,
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'descr' },
            },
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'id' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.dataReady = true
      this.$nextTick(() => {
        this.corner1 = document.getElementById(`corner1_${this.uuid}`)
        this.thStickRow = document.getElementById(`th_stick_row_${this.uuid}`)
        this.stickTable()
      })
    },
    stickTable() {
      const offset1w = this.corner1.offsetWidth
      const offset1h = this.thStickRow.offsetHeight
      document.querySelectorAll('.corner2').forEach(cell => { const b = cell; b.style.left = `${offset1w}px` })
      document.querySelectorAll('.stick-col2').forEach(cell => { const b = cell; b.style.left = `${offset1w}px` })
      document.querySelectorAll('.th-row2').forEach(cell => { const b = cell; b.style.top = `${offset1h}px` })
    },
    paycalItems() {
      return this.qryRefPaycal.recordset.records.filter(rec => rec.parentlevel.value === 1 || rec.cbs_visible)
    },
    expandPaycal(rec) {
      if (!('cbs_expanded' in rec)) {
        this.$set(rec, 'cbs_expanded', true)
        this.$set(rec, 'cbs_visible', true)
      } else {
        const b = rec
        b.cbs_expanded = !rec.cbs_expanded
      }
      this.setChildrenVisible(rec, rec.cbs_expanded)
      this.$nextTick(() => this.stickTable())
    },
    setChildrenVisible(parent, visible) {
      this.qryRefPaycal.recordset.records.filter(rec => rec.parent.value === parent.id.value).forEach(rec => {
        if ('cbs_visible' in rec) {
          const b = rec
          b.cbs_visible = visible
        } else {
          this.$set(rec, 'cbs_visible', visible)
        }
        if (rec.isfolder.value === true && (rec.cbs_expanded || visible === false)) {
          this.setChildrenVisible(rec, visible)
        }
      })
    },
    expandAllPaycal(rec) {
      if (!('cbs_expanded' in rec)) {
        this.$set(rec, 'cbs_expanded', true)
        this.$set(rec, 'cbs_visible', true)
      } else {
        const b = rec
        b.cbs_expanded = !rec.cbs_expanded
      }
      this.setChildrenVisible(rec, rec.cbs_expanded)
      this.qryRefPaycal.recordset.records.filter(r => r.parent.value === rec.id.value).forEach(r => this.expandAllPaycal(r))
    },
    methodSid(paycal) {
      const method = this.qryMethod.recordset.records.find(r => r.id.value === paycal.paycal_enter_method.value)
      return method ? method.sid.value : 'none'
    },
    requestLines(paycal) {
      if ((this.paycalType(paycal) === 'units' || this.paycalType(paycal) === 'treasury') && paycal.cbs_expanded) {
        return this.qryPaycal.recordset.records.filter(r => r.paycal_item.value === paycal.id.value).reduce((set, rec) => {
          if (set.some(el => el.cash_request_sid.value === rec.cash_request_sid.value)) return set
          return [...set, rec] // добавляем к аккумулятору и возвращаем новый аккумулятор
        }, [])
      }
      return []
    },
    requestLinesCount(paycal) {
      const res = this.qryPaycal.recordset.records.filter(r => r.paycal_item.value === paycal.id.value).reduce((set, rec) => {
        if (set.some(el => el.cash_request_sid.value === rec.cash_request_sid.value)) return set
        return [...set, rec] // добавляем к аккумулятору и возвращаем новый аккумулятор
      }, [])
      return res.length
    },
    expandLines(rec) {
      if (!('cbs_expanded' in rec)) {
        this.$set(rec, 'cbs_expanded', true)
        this.$set(rec, 'cbs_visible', true)
      } else {
        const b = rec
        b.cbs_expanded = !rec.cbs_expanded
      }
    },
    weekTitle(nr) {
      const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      const st = new Date(year, month - 1, day + 7 * (nr - 1))
      const fn = new Date(year, month - 1, day + 7 * (nr) - 1)
      // return `${this.dateToString(st)} ${this.dateToString(fn)}`
      return { start: this.dateToString(st), finish: this.dateToString(fn) }
    },
    dateToString(dt) {
      // Извлекаем компоненты
      const year = dt.getFullYear()
      const month = String(dt.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const day = String(dt.getDate()).padStart(2, '0') // Добавляем ведущий ноль
      // Форматируем строку в формате DD.MM.YYYY
      return `${day}.${month}.${year}`
    },
    dateToUTCString(dt) {
      // Извлекаем компоненты
      const year = dt.getFullYear()
      const month = String(dt.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const day = String(dt.getDate()).padStart(2, '0') // Добавляем ведущий ноль
      // Форматируем строку в формате DD.MM.YYYY
      // return `${day}.${month}.${year}`
      return `${year}-${month}-${day}`
    },
    getSubRecordAmount(parentRec, periodNum, currency) {
      if (parentRec.currency.title !== currency) return null
      const strDt = this.getStrDate(periodNum)
      const subRec = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === parentRec.cash_request_sid.value && r.date.value.startsWith(strDt))
      if (subRec) {
        if (subRec.curamount.value === null || subRec.curamount.value === 0) return null
        return subRec.curamount.value.toLocaleString()
      }
      return null
    },
    getRecord(paycal, periodNum, currency) {
      const strDt = this.getStrDate(periodNum)
      const res = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === paycal.cash_request_sid.value && r.date.value.startsWith(strDt) && r.currency.title === currency)
      if (res) {
        // console.log('res found')
        return res
      }
      // console.log(`res not found : paycal, periodNum = ${periodNum}, strDt = ${strDt}, currency = ${currency}`)
      // this.counter += 1
      const n = this.getNewRecord(paycal, periodNum)
      // console.log('n', JSON.stringify(n))
      this.qryPaycal.recordset.records.push(n)
      return n
    },
    getRecordByPaycalItem(paycalItem, periodNum, currency) {
      const strDt = this.getStrDate(periodNum)
      const res = this.qryPaycal.recordset.records.find(r => r.paycal_item.value === paycalItem.id.value && r.date.value.startsWith(strDt) && r.currency.title === currency)
      if (res) {
        return res
      }
      const n = this.getNewRecordByPaycalItem(paycalItem, periodNum, currency)
      this.qryPaycal.recordset.records.push(n)
      return n
    },
    getNewRecordByPaycalItem(paycalItem, periodNum, currency) {
      const strDt = this.getStrDate(periodNum)
      const cur = this.qryCurrency.recordset.records.find(r => r.name.value === currency)
      const n = {}
      this.qryPaycal.columns.filter(c => c.key !== 'actions').forEach(col => {
        n[col.entitysid] = {}
        n[col.entitysid].value = col.default.value
        n[col.entitysid].title = col.default.title
      })
      n.id.value = null
      n.id.title = null
      n.sid.value = null
      n.sid.title = null
      n.scenario.value = this.qryScenario.recordset.records[0].id.value
      n.scenario.title = this.qryScenario.recordset.records[0].name.value
      n.date.value = strDt
      n.date.title = null
      n.paycal_item.value = paycalItem.id.value
      n.paycal_item.title = paycalItem.name.value
      n.currency.value = cur.id.value
      n.currency.title = cur.name.value
      n.curamount.value = null
      n.curamount.title = null
      n.cash_request_sid.value = `dummy_${useCubus.guid()}`
      n.cash_request_sid.title = null
      return n
    },
    getNewRecord(paycal, periodNum) {
      const strDt = this.getStrDate(periodNum)
      const n = {}
      this.qryPaycal.columns.forEach(col => {
        if (col.key !== 'actions') {
          n[col.entitysid] = {}
          n[col.entitysid].value = paycal[col.entitysid].value
          n[col.entitysid].title = paycal[col.entitysid].title
        }
      })
      n.id.value = null
      n.id.title = null
      n.sid.value = null
      n.sid.title = null
      n.date.value = strDt
      n.date.title = null
      n.curamount.value = null
      n.curamount.title = null
      return n
    },
    getStrDate(periodNum) {
      const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      const st = new Date(year, month - 1, day + 7 * (periodNum - 1))
      return this.dateToString(st)
    },
    paycalType(rec) {
      if (rec.isfolder.value === true) return 'folder'
      return this.methodSid(rec)
    },
    styleStickCol2() {
      if (this.corner1) {
        return {
          left: `${this.corner1.offsetWidth}px`,
        }
      }
      return {}
    },
    getTotalAmount(paycal, periodNum, currency) {
      if (paycal.isfolder.value === true) {
        const dir = this.getDirectionSid(paycal)
        return this.qryRefPaycal.recordset.records
          .filter(r => r.parent.value === paycal.id.value)
          .reduce((sum, rec) => sum + (dir === 'none' && this.getDirectionSid(rec) === 'outcome' ? -1 : 1) * this.getTotalAmount(rec, periodNum, currency), 0)
      }
      return this.qryPaycal.recordset.records
        .filter(r => r.paycal_item.value === paycal.id.value && r.date.value.startsWith(this.getStrDate(periodNum)) && r.currency.title === currency)
        .reduce((sum, rec) => sum + (rec.curamount.value || null), 0)
    },
    amountToStr(amount) {
      if (amount === 0) return null
      return amount.toLocaleString()
    },
    getCursor() {
      return this.periodNums.map(p => this.curs.map(c => ({ periodNum: p, curName: c }))).flat()
    },
    onEdit(rec, newValue) {
      const b = rec
      b.curamount.value = newValue
      b.curamount.status = 'changed'
      this.saveRecord(rec)
      if (rec.linked_cash_request_sid.value && this.paycalItem(rec).sid.value === 'oper_out_forex') {
        this.addForex(rec)
      }
    },
    async saveRecord(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'cube_paycal', record })
        this.afterSave(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSave(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryPaycal.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
      this.recalcSaldo()
    },
    async saveRecordWithoutRecalc(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'cube_paycal', record })
        this.afterSaveWithoutRecacl(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveWithoutRecacl(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryPaycal.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
    },
    getDirectionSid(paycal) {
      const rec = this.qryCfDirection.recordset.records.find(r => r.id.value === paycal.cf_direction.value)
      return rec ? rec.sid.value : 'none'
    },
    getSaldoOut(periodNum, currency) {
      return this.getSaldoIn(periodNum, currency) + this.qryRefPaycal.recordset.records
        .filter(r => r.parentlevel.value === 1 && r.sid.value !== 'saldo_in' && r.sid.value !== 'saldo_out')
        .reduce((sum, rec) => sum + this.getTotalAmount(rec, periodNum, currency), 0)
    },
    getSaldoIn(periodNum, currency) {
      const paycal = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_in')
      if (periodNum === 1) {
        return this.getTotalAmount(paycal, periodNum, currency)
      }
      return this.getSaldoOut(periodNum - 1, currency)
    },
    styleAmountCell(paycal, line, crs) {
      const rw = { backgroundColor: 'lightyellow' }
      const ro = {}
      if (paycal.sid.value === 'saldo_out') return ro
      if (paycal.sid.value === 'saldo_in' && crs.periodNum > 1) return ro
      if (line.currency.title === crs.curName) return rw
      return ro
    },
    recalcSaldo() {
      this.recalcSaldoOut(1)
      const pers = [2, 3, 4, 5, 6]
      pers.forEach(p => {
        this.recalcSaldoIn(p)
        this.recalcSaldoOut(p)
      })
    },
    recalcSaldoOut(periodNum) {
      this.curs.forEach(c => this.recalcSaldoOutCur(periodNum, c))
    },
    recalcSaldoIn(periodNum) {
      this.curs.forEach(c => this.recalcSaldoInCur(periodNum, c))
    },
    recalcSaldoOutCur(periodNum, currency) {
      const paycalItem = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_out')
      // const paycalid = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_out').id.value
      // const dt = this.getStrDate(periodNum)
      // const rec = this.qryPaycal.recordset.records.find(r => r.paycal_item.value === paycalid && r.date.value.startsWith(dt) && r.currency.title === currency)
      // const rec = this.getRecord(paycalItem, periodNum, currency)
      const rec = this.getRecordByPaycalItem(paycalItem, periodNum, currency)
      // if (!rec) return
      rec.curamount.value = this.getSaldoOut(periodNum, currency)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },
    recalcSaldoInCur(periodNum, currency) {
      const paycal = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_in')
      // const paycalid = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_in').id.value
      // const dt = this.getStrDate(periodNum)
      // const rec = this.qryPaycal.recordset.records.find(r => r.paycal_item.value === paycalid && r.date.value.startsWith(dt) && r.currency.title === currency)
      // const rec = this.getRecord(paycal, periodNum, currency)
      const rec = this.getRecordByPaycalItem(paycal, periodNum, currency)
      // if (!rec) return
      rec.curamount.value = this.getSaldoIn(periodNum, currency)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },
    addLine(paycalItem) {
      const n = this.getNewRecordByPaycalItem(paycalItem, 1, 'KZT')
      this.qryPaycal.recordset.records.push(n)
    },
    deleteLine(line) {
      console.log('deleteLine', line)
      this.qryPaycal.recordset.records.filter(r => r.cash_request_sid.value === line.cash_request_sid.value).forEach(r => this.deleteRecord(r))
      while (this.qryPaycal.recordset.records.some(r => r.cash_request_sid.value === line.cash_request_sid.value)) {
        this.qryPaycal.recordset.records.splice(this.qryPaycal.recordset.records.findIndex(r => r.cash_request_sid.value === line.cash_request_sid.value), 1)
      }
      this.recalcSaldo()
    },
    async deleteRecord(rec) {
      if (rec.id.value !== null && rec.id.value !== 0) {
        await useCubus.delRecord(this, { keymode: 'sid', objectsid: 'cube_paycal', record: rec })
      }
      // console.log('record count 1', this.qryPaycal.recordset.records.length)
      // const ind = this.qryPaycal.recordset.records.findIndex(r => r.cash_request_sid.value === rec.cash_request_sid.value)
      // console.log('ind', ind)
      // this.qryPaycal.recordset.records.splice(this.qryPaycal.recordset.records.indexOf(rec), 1)
      // console.log('record count 2', this.qryPaycal.recordset.records.length)
    },
    refCur() {
      return this.qryCurrency.recordset.records.filter(r => this.curs.includes(r.name.value)).map(r => ({ value: r.id.value, title: r.name.value }))
    },
    onUpdateCell(rec, key, val) {
      if (key === 'curamount') {
        const b = rec
        b[key].value = val.value
        b[key].title = val.title
        b[key].status = 'changed'
        this.saveRecord(rec)
      } else {
        this.qryPaycal.recordset.records.filter(r => r.cash_request_sid.value === rec.cash_request_sid.value).forEach(r => {
          const b = r
          b[key].value = val.value
          b[key].title = val.title
          b[key].status = 'changed'
          this.saveRecord(r)
        })
      }
    },
    paycalItem(rec) {
      return this.qryRefPaycal.recordset.records.find(r => r.id.value === rec.paycal_item.value)
    },
    addForex(rec) {
      const forexRec = this.getLinkedRecord(rec)
      if (forexRec) {
        const rate = this.getRateRecord(rec.currency.title)
        const linkedRate = this.getRateRecord(forexRec.currency.title)
        forexRec.curamount.value = rec.curamount.value * (rate.currency_rate.value / linkedRate.currency_rate.value)
        forexRec.curamount.title = null
        forexRec.curamount.status = 'changed'
        this.saveRecord(forexRec)
      }
    },
    getLinkedRecord(srcRecord) {
      const rec = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === srcRecord.linked_cash_request_sid.value && r.date.value === srcRecord.date.value)
      if (rec) return rec
      const recWoDate = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === srcRecord.linked_cash_request_sid.value)
      if (recWoDate) {
        recWoDate.id.value = null
        recWoDate.id.title = null
        recWoDate.sid.value = null
        recWoDate.sid.title = null
        recWoDate.date.value = srcRecord.date.value
        recWoDate.date.title = srcRecord.date.title
        recWoDate.curamount.value = null
        recWoDate.curamount.title = null
        this.qryPaycal.recordset.records.push(recWoDate)
        return recWoDate
      }
      return null
    },
    refFirm() {
      return this.qryFirm.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    refUnit() {
      return this.qryUnit.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    getRateRecord(currency) {
      const rec = this.qryRate.recordset.records.find(r => r.currency.title === currency)
      if (rec) return rec
      const newrec = this.getNewRateRecord(currency)
      this.qryRate.recordset.records.push(newrec)
      return newrec
    },
    getNewRateRecord(currency) {
      const n = {}
      this.qryRate.columns.filter(c => c.key !== 'actions').forEach(col => {
        n[col.entitysid] = {}
        n[col.entitysid].value = col.default.value
        n[col.entitysid].title = col.default.title
      })
      const cur = this.qryCurrency.recordset.records.find(r => r.name.value === currency)
      n.scenario.value = this.qryScenario.recordset.records[0].id.value
      n.scenario.title = this.qryScenario.recordset.records[0].name.value
      if (cur) {
        n.currency.value = cur.id.value
        n.currency.title = cur.name.value
      }
      n.date.value = this.period
      n.currency_rate.value = 1
      return n
    },
    onEditRate(rec, newValue) {
      const b = rec
      b.currency_rate.value = newValue
      b.currency_rate.status = 'changed'
      this.saveRateRecord(rec)
    },
    async saveRateRecord(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'cube_paycal_rate', record })
        this.afterSaveRate(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveRate(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryRate.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
      this.recalcForex()
    },
    recalcForex() {
      this.qryPaycal.recordset.records.filter(r => r.linked_cash_request_sid.value && this.paycalItem(r).sid.value === 'oper_out_forex').forEach(r => this.addForex(r))
      this.recalcSaldo()
    },
    mountPaycal() {
      this.$refs[`modal_mount_${this.uuid}`].show()
    },
    async runMount() {
      console.log('runMount')
      await useCubus.runMount(this, {
        objectsid: 'mount_cube_paycal',
        keymode: 'sid',
        record: {
          start: { value: this.period, title: null },
          finish: { value: this.weekTitle(6).finish, title: null },
        },
      })
      this.init()
    },
    onCancelMount() {
      console.log('onCancelMount')
    },
    onCloseMount() {
      console.log('onCloseMount')
    },
    getColumn(key) {
      return this.qryPaycal.columns.find(c => c.entitysid === key)
    },
    getCondition(key) {
      const col = this.getColumn(key)
      if (!col) return null
      const cond = this.filter.node.conds.find(c => c.args[0].value === col.entityid)
      if (cond) return cond
      const newCond = this.getNewCondition(col)
      this.filter.node.conds.push(newCond)
      return newCond
    },
    getNewCondition(col) {
      const operSid = col.datatype === 'string' ? 'contain' : 'equal'
      return {
        isactive: false,
        oper: { sid: operSid },
        args: [
          { type: { sid: 'entity' }, value: col.entityid },
          { type: { sid: 'value' }, value: null },
        ],
      }
    },
    applyFilter() {
      this.loadQryPaycal()
    },
  },
}
</script>

<style scoped>
.corner1 {
  padding: 0.5rem;
  text-align: center;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}
.corner2 {
  padding: 0.5rem;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 3;
}
.th-row1 {
  padding: 0.5rem;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 2;
}
.th-row2 {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  position: sticky;
  z-index: 2;
}
.stick-col1 {
  position: sticky;
  left: 0;
  z-index: 1;
}
.stick-col2 {
  position: sticky;
  z-index: 1;
}
.cell-num {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  text-align: right;
}
.cbs-cell {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.th-line2 {
  position: sticky;
  z-index: 2;
}
.flt0 {
  text-transform: none;
  font-weight: normal;
}
</style>
