<template>
  <div>
    <b-button-toolbar
        key-nav
        aria-label="Toolbar with button groups"
        class="demo-inline-spacing"
    >
      <b-button-group
          size="sm"
          class="cbs-inline-spacing mb-1"
      >
        <b-button
            title="Создать новую заявку"
            :variant="hasEmptyRecord() ? 'outline-primary' : 'primary'" :disabled="hasEmptyRecord()"
            @click="createRequest()"
        >
          <feather-icon icon="PlusIcon" />&nbsp;Создать
        </b-button>
      </b-button-group>
      <b-button-group
          size="sm"
          class="cbs-inline-spacing mb-1"
      >
        <b-button
            title="Удалить заявку"
            :disabled="selected().length === 0"
            :variant="selected().length === 0 ? 'outline-danger' : 'danger'"
            @click="deleteRecords()"
        >
          <feather-icon icon="TrashIcon" />
          Удалить ({{ selected().length }})
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <div v-if="dataReady" style="overflow: scroll; max-height: 70vh; min-height: 20rem;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th rowspan="2" :id="`firstcol_${uuid}`" class="stick-th1">ID</th>
            <th rowspan="2" class="cbs-th">Утв</th>
            <th class="cbs-th" :id="`secondcol_${uuid}`">Подразделение</th>
            <th rowspan="2" class="cbs-th">Вид</th>
            <th class="cbs-th">Статья ПК</th>
            <!--<th v-if="true" :id="`secondcol_${uuid}`" class="cbs-th stick-th2">Комментарий</th>-->
            <th v-if="true" class="cbs-th">Описание</th>
            <th class="cbs-th">Контрагент</th>
            <th v-if="false" rowspan="2" class="cbs-th">Договор</th>
            <th class="cbs-th">Валюта</th>
            <th rowspan="2" class="cbs-th">{{ weekTitle(1) }}</th>
            <th rowspan="2" class="cbs-th">{{ weekTitle(2) }}</th>
            <th rowspan="2" class="cbs-th">{{ weekTitle(3) }}</th>
            <th rowspan="2" class="cbs-th">{{ weekTitle(4) }}</th>
            <th rowspan="2" class="cbs-th">{{ weekTitle(5) }}</th>
            <th rowspan="2" class="cbs-th">{{ weekTitle(6) }}</th>
            <th rowspan="2" class="cbs-th">Всего</th>
            <th rowspan="2" class="cbs-th">Автор</th>
            <th rowspan="2" class="cbs-th">Создано</th>
            <th v-if="false" rowspan="2" class="cbs-th">Утв Польз</th>
            <th v-if="false" rowspan="2" class="cbs-th">Дата Утв</th>
          </tr>
          <tr>
            <!--<th v-if="true" class="flt th-stick-col2">
              <cbs-budreq-filter :column="getColumn('descr')" :condition="getCondition('descr')" @apply="applyFilter()" />
            </th>-->
            <th class="flt th-row2">
              <cbs-budreq-filter :column="getColumn('business_unit')" :reference="refs.business_unit" :condition="getCondition('business_unit')" @apply="applyFilter()" />
            </th>
            <th class="flt th-row2">
              <cbs-budreq-filter :column="getColumn('paycal_item')" :reference="refs.paycal_item" :condition="getCondition('paycal_item')" @apply="applyFilter()" />
            </th>
            <th v-if="true" class="flt th-row2">
              <cbs-budreq-filter :column="getColumn('descr')" :condition="getCondition('descr')" @apply="applyFilter()" />
            </th>
            <th class="flt th-row2">
              <cbs-budreq-filter :column="getColumn('firm')" :reference="refs.firm" :condition="getCondition('firm')" @apply="applyFilter()" />
            </th>
            <th class="flt th-row2">
              <cbs-budreq-filter :column="getColumn('currency')" :reference="refs.currency" :condition="getCondition('currency')" @apply="applyFilter()" />
            </th>
          </tr>
        </thead>
        <tbody v-for="rec in qryCashreq.recordset.records" :key="rec.id.value">
          <tr style="padding: 0;">
            <td class="stick-col1 bg-lighten-5"
                :class="rec.approved.value === true ? 'bg-success' : (isValid(rec) ? 'bg-info' : 'bg-warning')"
                style="padding: 0.5rem; text-align: center;"
                :style="rec.cbs_access === 'rw' ? 'cursor: pointer;' : ''"
                @click="selectRec(rec)"
            >
              <feather-icon v-if="rec.cbs_access === 'ro'" icon="LockIcon" />
              <feather-icon v-else :icon="rec.cbs_selected ? 'CheckSquareIcon' : 'SquareIcon'" />
              {{ rec.id.value }}
            </td>
            <td style="text-align: center; padding: 0;"
                :style="rec.approved.value || isValid(rec) ? 'cursor: pointer;' : ''"
                @click="clickApprove(rec)"
            >
              <feather-icon size="16" :icon="rec.approved.value ? 'CheckSquareIcon' : (isValid(rec) ? 'SquareIcon' : 'XSquareIcon') " />
            </td>
            <!--<td v-if="true" class="cbs-cell stick-col2 table-light">
              <cbs-budreq-cell align-left :record="rec" key-value="descr" @edit="onEdit(rec, 'descr', $event)" />
            </td>-->
            <td class="cbs-cell" :class="rec.business_unit.value ? '' : 'bg-danger bg-lighten-5'">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryCashreq.columns.find(c => c.entitysid === 'business_unit')"
                                     :prop-ref="refs.business_unit" @updateCell="onUpdateCell(rec, 'business_unit', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
            </td>
            <td class="cbs-cell" style="text-align: center;">{{ cfDir(rec.paycal_item.value) }}</td>
            <td class="cbs-cell" :class="rec.paycal_item.value ? '' : 'bg-danger bg-lighten-5'">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryCashreq.columns.find(c => c.entitysid === 'paycal_item')"
                                     :prop-ref="refPaycal(rec)" @updateCell="onUpdateCell(rec, 'paycal_item', $event)"
                                     :key-mode="'sid'"
              />
            </td>
            <td v-if="true" class="cbs-cell">
              <cbs-budreq-cell align-left :record="rec" key-value="descr" @edit="onEdit(rec, 'descr', $event)" />
            </td>
            <td class="cbs-cell">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryCashreq.columns.find(c => c.entitysid === 'firm')"
                                     :prop-ref="refs.firm" @updateCell="onUpdateCell(rec, 'firm', $event)"
                                     :key-mode="'sid'"
              />
            </td>
            <td v-if="false" class="cbs-cell">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryCashreq.columns.find(c => c.entitysid === 'contract')"
                                     :prop-ref="refContracts(rec)" @updateCell="onUpdateCell(rec, 'contract', $event)"
                                     :key-mode="'sid'"
              />
            </td>
            <td class="cbs-cell">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryCashreq.columns.find(c => c.entitysid === 'currency')"
                                     :prop-ref="refs.currency" @updateCell="onUpdateCell(rec, 'currency', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
            </td>
            <td class="cbs-cell">
              <cbs-budreq-cell :record="getSubRecord(rec, 1)"
                               key-value="curamount"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, 1), $event)"
                               style="text-align: right;"
              />
            </td>
            <td class="cbs-cell">
              <cbs-budreq-cell :record="getSubRecord(rec, 2)"
                               key-value="curamount"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, 2), $event)"
                               style="text-align: right;"
              />
            </td>
            <td class="cbs-cell">
              <cbs-budreq-cell :record="getSubRecord(rec, 3)"
                               key-value="curamount"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, 3), $event)"
                               style="text-align: right;"
              />
            </td>
            <td class="cbs-cell">
              <cbs-budreq-cell :record="getSubRecord(rec, 4)"
                               key-value="curamount"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, 4), $event)"
                               style="text-align: right;"
              />
            </td>
            <td class="cbs-cell">
              <cbs-budreq-cell :record="getSubRecord(rec, 5)"
                               key-value="curamount"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, 5), $event)"
                               style="text-align: right;"
              />
            </td>
            <td class="cbs-cell">
              <cbs-budreq-cell :record="getSubRecord(rec, 6)"
                               key-value="curamount"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, 6), $event)"
                               style="text-align: right;"
              />
            </td>
            <td class="cbs-cell" style="text-align: right;" :class="totalAmount(rec) === 0 ? 'bg-danger bg-lighten-5' : ''">{{ totalAmount(rec).toLocaleString() }}</td>
            <td class="cbs-cell">{{ rec.authorsid.title}}</td>
            <td class="cbs-cell">{{ rec.created.value }}</td>
            <td v-if="false" class="cbs-cell">{{ rec.approval_usersid.title }}</td>
            <td v-if="false" class="cbs-cell">{{ rec.approval_date.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <cbs-pagination v-if="dataReady" :page-size="pageSize" :record-count="recordcount" :current-page="currentPage"
                    @page-change="onChangePageNumber"
    />
    <cbs-debug v-if="true || isRoot" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BButtonToolbar,
} from 'bootstrap-vue'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import CbsPagination from '@/cubus/components/pagination/CbsPagination.vue'
import CbsBudreqFilter from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqFilter.vue'

export default {
  name: 'CbsCashRequestTable',
  components: {
    CbsBudreqFilter,
    CbsPagination,
    CbsBudreqCell,
    CbsReferencePicker3,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    CbsDebug,
  },
  props: {
    period: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      objCashreq: null,
      objCashreqLine: null,
      qryMethod: null,
      qryPaycal: null,
      qryCashreq: null,
      uuid: null,
      refs: {},
      dataReady: false,
      qryContract: null,
      qryUser: null,
      qryEmpl: null,
      currentEmpl: null,
      currentUnit: null,
      pageSize: 10,
      recordcount: 0,
      currentPage: 1,
      firstColumn: null,
      secondColumn: null,
      curKzt: null,
      isAdmin: false,
      filter: {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [
            {
              isactive: true,
              oper: { sid: 'and', name: 'AND' },
              nodes: [],
              conds: [],
            },
          ],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
            this.conditionDate(),
          ],
        },
      },
      qryCfDirection: null,
      qryTblUnitPaycal: null,
      recentUnit: { value: null, title: null },
    }
  },
  setup() {
    const session = useCubus.getSession()
    return {
      session,
      isRoot: useCubus.isRoot(),
    }
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.isAdmin = this.session.user.sid === 'root'
          || this.session.user.roles.some(r => r.sid === 'admin')
          || this.session.user.roles.some(r => r.sid === 'treasury')
      this.initFilter()
      this.initStartDate()
      this.loadObjCashreq()
      this.loadQryContract()
    },
    initStartDate() {
      const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      this.startDate = new Date(year, month - 1, day)
    },
    async loadObjCashreq() {
      const params = {
        objectsid: 'cash_request',
      }
      this.objCashreq = await useCubus.loadObject(this, params)
      const prmUser = {
        keymode: 'sid',
        objectsid: 'user',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'sid' },
                  { type: { sid: 'value' }, value: this.session.user.sid },
                ],
              },
            ],
          },
        },
      }
      this.qryUser = await useCubus.loadQuery(this, prmUser)
      this.qryEmpl = await useCubus.loadQuery(this, { keymode: 'sid', objectsid: 'employee' })
      if (this.qryEmpl) {
        this.currentEmpl = this.qryEmpl.recordset.records.find(e => e.sid.value === this.session.user.sid)
        if (this.currentEmpl) {
          this.currentUnit = this.qryEmpl.recordset.records.find(e => e.sid.value === this.currentEmpl.business_unit)
          this.recentUnit.value = this.currentUnit.value
          this.recentUnit.title = this.currentUnit.title
        }
      }
      const prmPaycal = {
        keymode: 'sid',
        objectsid: 'ref_paycal',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'paycal_enter_method' },
                  { type: { sid: 'value' }, value: 1 },
                ],
              },
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'code' },
            },
          ],
        },
        pageSize: 100000,
      }
      const prmPaycalAdmin = {
        keymode: 'sid',
        objectsid: 'ref_paycal',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'code' },
            },
          ],
        },
        pageSize: 100000,
      }
      this.qryPaycal = await useCubus.loadQuery(this, this.isAdmin ? prmPaycalAdmin : prmPaycal)
      const prmCfDir = {
        keymode: 'sid',
        objectsid: 'ref_cf_direction',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'code' },
            },
          ],
        },
        pageSize: 100000,
      }
      this.qryCfDirection = await useCubus.loadQuery(this, prmCfDir)
      this.qryTblUnitPaycal = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tbl_unit_paycal',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
    },
    async loadQryContract() {
      const params = {
        keymode: 'sid',
        withsubrecords: true,
        objectsid: 'refcontract',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      }
      this.qryContract = await useCubus.loadQuery(this, params)
      await this.loadQryCashreq('init')
    },
    async loadQryCashreq(caller) {
      const params = {
        keymode: 'sid',
        withsubrecords: true,
        objectsid: 'cash_request',
        filter: this.filter,
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'desc' },
              entity: { sid: 'id' },
            },
          ],
        },
        pageSize: this.pageSize,
        offset: this.pageSize * (this.currentPage - 1),
      }
      this.qryCashreq = await useCubus.loadQuery(this, params)
      if (this.qryCashreq && caller === 'init') {
        this.initRefs()
      }
      this.recordcount = this.qryCashreq.recordcount
      this.initCashreqAccess()
      this.dataReady = true
      this.$nextTick(() => {
        this.firstColumn = document.getElementById(`firstcol_${this.uuid}`)
        this.secondColumn = document.getElementById(`secondcol_${this.uuid}`)
        this.stickSecondCol()
      })
    },
    stickSecondCol() {
      const offset1w = this.firstColumn.offsetWidth
      document.querySelectorAll('.stick-th2').forEach(cell => { const b = cell; b.style.left = `${offset1w}px` })
      document.querySelectorAll('.stick-col2').forEach(cell => { const b = cell; b.style.left = `${offset1w}px` })
      document.querySelectorAll('.th-stick-col2').forEach(cell => { const b = cell; b.style.left = `${offset1w}px` })
      if (this.secondColumn) {
        const offset2h = this.secondColumn.offsetHeight
        document.querySelectorAll('.th-row2').forEach(cell => { const b = cell; b.style.top = `${offset2h}px` })
        document.querySelectorAll('.th-stick-col2').forEach(cell => { const b = cell; b.style.top = `${offset2h}px` })
      }
    },
    initCashreqAccess() {
      this.qryCashreq.recordset.records.forEach(r => {
        const acc = (r.date.value === this.period && r.approved.value === false) ? 'rw' : 'ro'
        this.setAccess(r, acc)
        // this.$set(r, 'cbs_access', acc)
      })
    },
    weekTitle(nr) {
      const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      const st = new Date(year, month - 1, day + 7 * (nr - 1))
      const fn = new Date(year, month - 1, day + 7 * (nr) - 1)
      return `${this.dateToString(st)} ${this.dateToString(fn)}`
    },
    dateToString(dt) {
      // Извлекаем компоненты
      const year = dt.getFullYear()
      const month = String(dt.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const day = String(dt.getDate()).padStart(2, '0') // Добавляем ведущий ноль
      // Форматируем строку в формате DD.MM.YYYY
      return `${day}.${month}.${year}`
    },
    hasEmptyRecord() {
      if (!this.qryCashreq) return true
      return this.qryCashreq.recordset.records.some(r => r.id.value === null)
    },
    createRequest() {
      const rec = this.getNewRecord()
      this.qryCashreq.recordset.records.unshift(rec)
      this.$nextTick(() => {
        this.stickSecondCol()
      })
    },
    getNewRecord() {
      const n = {}
      this.qryCashreq.columns.forEach(fld => {
        if (fld.default) {
          n[fld.entitysid] = {}
          n[fld.entitysid].value = fld.default.value
          n[fld.entitysid].title = fld.default.title
        }
      })
      n.del.value = false
      n.date.value = this.period
      /*
      if (this.currentUnit) {
        n.business_unit.value = this.currentUnit.value
        n.business_unit.title = this.currentUnit.title
      }
      */
      if (this.recentUnit) {
        n.business_unit.value = this.recentUnit.value
        n.business_unit.title = this.recentUnit.title
      }
      if (this.curKzt) {
        n.currency.value = this.curKzt.value
        n.currency.title = this.curKzt.title
      }
      n.authorsid.value = this.session.user.sid
      n.authorsid.title = this.session.user.name
      n.created.value = useCubus.dateToString(new Date())
      n.cbs_access = 'rw'
      n.sub_recordsets = {}
      this.objCashreq.children.forEach(c => {
        n.sub_recordsets[c.object.sid] = { records: [] }
      })
      return JSON.parse(JSON.stringify(n))
    },
    selected() {
      if (!this.qryCashreq) return []
      return this.qryCashreq.recordset.records.filter(r => r.cbs_selected)
    },
    deleteRecords() {
      this.qryCashreq.recordset.records.filter(r => r.cbs_selected).filter(r => r.cbs_access === 'rw').forEach(r => {
        this.deleteSubRecords(r)
        if (r.sid.value) {
          this.deleteRecord(this.objCashreq.object.id, r, this.qryCashreq.recordset.records)
        } else {
          this.qryCashreq.recordset.records.splice(this.qryCashreq.recordset.records.indexOf(r), 1)
        }
      })
    },
    deleteSubRecords(record) {
      this.objCashreq.children.forEach(c => {
        record.sub_recordsets[c.object.sid].records.forEach(r => {
          if (r.id.value) this.deleteRecord(c.object.id, r, record.sub_recordsets[c.object.sid].records)
        })
      })
    },
    async deleteRecord(objectid, record, records) {
      try {
        await useCubus.deleteRecord({ objectid, record })
        records.splice(this.qryCashreq.recordset.records.indexOf(record), 1)
      } catch (e) { console.error(e) }
    },
    selectRec(rec) {
      if (rec.approved.value === true) return
      if (!rec.cbs_selected) this.$set(rec, 'cbs_selected', true)
      else {
        const b = rec; b.cbs_selected = !rec.cbs_selected
      }
    },
    clickApprove(rec) {
      const b = rec
      if (rec.approved.value === false && !this.isValid(rec)) return
      b.approved.value = !rec.approved.value
      if (rec.approved.value === true) {
        b.approval_usersid.value = this.session.user.sid
        b.approval_usersid.title = this.session.user.name
        b.approval_date.value = useCubus.dateToString(new Date())
        if (rec.currency.value == null || rec.currency.value === 0) {
          b.currency.value = this.curKzt.value
          b.currency.title = this.curKzt.title
          b.currency.status = 'changed'
        }
        this.setAccess(rec, 'ro')
      } else {
        b.approval_usersid.value = null
        b.approval_usersid.title = null
        b.approval_date.value = null
        this.setAccess(rec, 'rw')
      }
      b.approved.status = 'changed'
      b.approval_date.status = 'changed'
      b.approval_usersid.status = 'changed'
      this.saveRecord(rec)
    },
    setAccess(rec, acc) {
      if (!('cbs_access' in rec)) {
        this.$set(rec, 'cbs_access', acc)
      } else {
        const b = rec; b.cbs_access = acc
      }
      if ('sub_recordsets' in rec && 'cash_request_amount' in rec.sub_recordsets) {
        rec.sub_recordsets.cash_request_amount.records.forEach(sr => {
          this.setAccess(sr, acc)
        })
      }
    },
    initRefs() {
      this.qryCashreq.columns.filter(c => c.datatype === 'ref' || c.datatype === 'refsid').forEach(c => {
        this.loadRef(c.entitysid)
      })
    },
    async loadRef(entitysid) {
      const ref = await useCubus.loadRef(this, { entitysid })
      if (ref) {
        this.$set(this.refs, entitysid, ref)
        if (entitysid === 'currency') {
          this.curKzt = this.refs.currency.find(r => r.sid === 'kzt')
        }
      }
    },
    onUpdateCell(rec, key, val) {
      const b = rec
      b[key].value = val.value
      b[key].title = val.title
      b[key].status = 'changed'
      if (key === 'firm') {
        b.contract.value = null
        b.contract.title = null
        b.contract.status = 'changed'
      }
      if (key === 'contract') {
        const contr = this.qryContract.recordset.records.find(c => c.id.value === val.value)
        if (contr) {
          const cur = contr.onec_transaction_currency.title
          const refcur = this.refs.currency.find(c => c.title === cur)
          if (refcur) {
            b.currency.value = refcur.value
            b.currency.title = refcur.title
            b.currency.status = 'changed'
          }
        }
      }
      if (key === 'business_unit') {
        this.recentUnit.value = val.value
        this.recentUnit.title = val.title
      }
      this.saveRecord(rec)
    },
    async saveRecord(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'cash_request', record })
        this.afterSave(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSave(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryCashreq.columns.forEach(fld => {
        if (fld.key !== 'actions') {
          i[fld.entitysid].value = savedRec[fld.entitysid].value
          i[fld.entitysid].title = savedRec[fld.entitysid].title
        }
      })
      this.objCashreq.children.forEach(child => {
        initRec.sub_recordsets[child.object.sid].records.forEach(subrec => { const sr = subrec; sr.ownersid.value = savedRec.sid.value })
      })
      i.status = 'saved'
    },
    getSubRecord(parentRec, periodNum) {
      const strDt = this.getStrDate(periodNum)
      const subRec = parentRec.sub_recordsets.cash_request_amount.records.find(r => r.date.value.startsWith(strDt))
      if (subRec) return subRec
      const newRec = this.getNewSubRecord(parentRec, strDt)
      parentRec.sub_recordsets.cash_request_amount.records.push(newRec)
      return newRec
    },
    getStrDate(periodNum) {
      const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      const st = new Date(year, month - 1, day + 7 * (periodNum - 1))
      return this.dateToString(st)
    },
    getNewSubRecord(parentRec, strDate) {
      const res = {}
      this.qryCashreq.subcolumns.cash_request_amount.columns.forEach(c => {
        res[c.entitysid] = c.default
      })
      res.del.value = false
      res.ownersid.value = parentRec.sid.value
      res.date.value = strDate
      res.created.value = useCubus.dateToString(new Date())
      res.authorsid.value = this.session.user.sid
      res.authorsid.title = this.session.user.name
      res.cbs_access = parentRec.cbs_access
      return JSON.parse(JSON.stringify(res))
    },
    onEditSub(rec, newValue) {
      const b = rec
      b.curamount.value = newValue
      b.curamount.status = 'changed'
      this.saveSubRecord(this.subObject('cash_request_amount'), rec)
    },
    subObject(sid) {
      return this.objCashreq.children.find(c => c.object.sid === sid)
    },
    async saveSubRecord(subObject, record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectid: subObject.object.id, record })
        this.afterSaveSub(record, saved, subObject)
      } catch (e) { console.error(e) }
    },
    afterSaveSub(initRec, savedRec, subObject) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.subColumns(subObject.object.sid).forEach(fld => {
        if (fld.key !== 'actions') {
          i[fld.entitysid].value = savedRec[fld.entitysid].value
          i[fld.entitysid].title = savedRec[fld.entitysid].title
        }
      })
      i.status = 'saved'
    },
    subColumns(sid) {
      return this.qryCashreq.subcolumns[sid].columns
    },
    refContracts(rec) {
      return this.qryContract.recordset.records.filter(c => c.firm.value === rec.firm.value).map(c => ({
        value: c.id.value,
        title: `${c.name.value} (${c.code.value})`,
      }))
    },
    refPaycal(rec) {
      return this.qryPaycal.recordset.records
        .filter(r => this.qryTblUnitPaycal.recordset.records.some(u => rec.business_unit.value === null || u.business_unit.value === null || (u.business_unit.value === rec.business_unit.value && u.paycal_item.value === r.id.value)))
        .map(c => ({
          value: c.id.value,
          title: `${c.name.value} (${c.code.value})`,
          disabled: c.isfolder.value,
        }))
    },
    onEdit(rec, key, newValue) {
      const b = rec
      b[key].value = newValue
      b[key].status = 'changed'
      this.saveRecord(rec)
    },
    onChangePageNumber(page) {
      this.currentPage = page
      this.loadQryCashreq('pager')
    },
    isValid(rec) {
      return rec.paycal_item.value !== null && rec.paycal_item.value !== 0
          && rec.business_unit.value !== null && rec.business_unit.value !== 0
          && this.totalAmount(rec) > 0
    },
    totalAmount(rec) {
      return rec.sub_recordsets.cash_request_amount.records.reduce((accumulator, item) => accumulator + (item.curamount.value || 0), 0)
    },
    getColumn(key) {
      return this.qryCashreq.columns.find(c => c.entitysid === key)
    },
    getCondition(key) {
      const col = this.getColumn(key)
      if (!col) return null
      const cond = this.filter.node.nodes[0].conds.find(c => c.args[0].value === col.entityid)
      if (cond) return cond
      const newCond = this.getNewCondition(col)
      this.filter.node.nodes[0].conds.push(newCond)
      return newCond
    },
    getNewCondition(col) {
      const operSid = (col.datatype === 'string' || col.datatype === 'text') ? 'contain' : 'equal'
      return {
        isactive: false,
        oper: { sid: operSid },
        args: [
          { type: { sid: 'entity' }, value: col.entityid },
          { type: { sid: 'value' }, value: null },
        ],
      }
    },
    applyFilter() {
      this.loadQryCashreq('filter')
    },
    conditionDate() {
      if (this.period === '0') {
        return {
          isactive: true,
          oper: { sid: 'isnull' },
          args: [
            { type: { sid: 'entitysid' }, value: 'date' },
          ],
        }
      }
      return {
        isactive: true,
        oper: { sid: 'equal' },
        args: [
          { type: { sid: 'entitysid' }, value: 'date' },
          { type: { sid: 'value' }, value: this.period },
        ],
      }
    },
    initFilter() {
      this.filter = {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [
            {
              isactive: true,
              oper: { sid: 'and', name: 'AND' },
              nodes: [],
              conds: [],
            },
          ],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
            this.conditionDate(),
          ],
        },
      }
    },
    cfDir(paycalid) {
      const paycal = this.qryPaycal.recordset.records.find(r => r.id.value === paycalid)
      if (!paycal) return null
      const dir = this.qryCfDirection.recordset.records.find(r => r.id.value === paycal.cf_direction.value)
      if (!dir) return null
      return dir.name.value
    },
  },
}
</script>

<style scoped>
.cbs-th {
  text-align: center;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 2;
}
.cbs-cell {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.stick-col1 {
  position: sticky;
  left: 0;
  z-index: 1;
}
.stick-col2 {
  position: sticky;
  z-index: 1;
}
.stick-th1 {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}
.stick-th2 {
  position: sticky;
  top: 0;
  z-index: 3;
}
.th-row2 {
  padding: 0;
  position: sticky;
  z-index: 2;
}
.th-stick-col2 {
  padding: 0;
  position: sticky;
  z-index: 3;
}
.flt {
  text-transform: none;
  font-weight: normal;
  padding: 0.2rem;
  text-align: center;
}
</style>
