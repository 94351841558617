<template>
<div>
  <div>
    <span style="cursor: pointer;" @click="expanded = !expanded">
      <feather-icon :icon="expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />&nbsp;{{ scenario.scenario.title }}
    </span>
  </div>
  <cbs-budget-trip-table v-if="expanded" :scenario="scenario" />
  <cbs-debug v-if="false && isRoot" :context="this" />
</div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsBudgetTripTable from '@/cubus/components/cbs-form/custom-forms/budget-trip/CbsBudgetTripTable.vue'

export default {
  name: 'CbsBudgetTripScenario',
  components: {
    CbsBudgetTripTable,
    CbsDebug,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const isRoot = useCubus.isRoot()
    return {
      isRoot,
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
}
</script>

<style scoped>

</style>