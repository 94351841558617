<template>
<div>
  <div v-if="qryBudgetScn">
    <cbs-budget-trip-scenario v-for="(scn, idx) in qryBudgetScn.recordset.records" :key="idx" :scenario="scn" style="margin-bottom: 2rem;" />
  </div>
  <cbs-debug v-if="isRoot" :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsBudgetTripScenario from '@/cubus/components/cbs-form/custom-forms/budget-trip/CbsBudgetTripScenario.vue'

export default {
  name: 'CbsBudgetTrip',
  components: {
    CbsBudgetTripScenario,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const isRoot = useCubus.isRoot()
    const cfg = {
      objSid_budgetScn: 'scenario_budget',
      filter_notDel: useCubus.filter_notDel(),
      orderby_code: useCubus.orderby_code(),
    }
    return {
      isRoot,
      cfg,
    }
  },
  data() {
    return {
      qryBudgetScn: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadQryBudgetScn()
    },
    async loadQryBudgetScn() {
      const params = {
        keymode: 'sid',
        objectsid: this.cfg.objSid_budgetScn,
        filter: this.cfg.filter_notDel,
        orderby: this.cfg.orderby_code,
        pageSize: 100000,
      }
      this.qryBudgetScn = await useCubus.loadQuery(this, params)
    },
  },
}
</script>

<style scoped>

</style>