<template>
<div>
  <div v-if="qryPeriods">
    <cbs-paycal-period v-for="(rec, idx) in qryPeriods.recordset.records" :key="idx" :period="rec.date.value.slice(0,10)" />
  </div>
  <cbs-paycal-period v-if="!isCurrentPeriodExists()" :period="currentPeriod" />
  <cbs-debug v-if="isRoot" :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsPaycalPeriod from '@/cubus/components/cbs-form/custom-forms/payment-calendar/CbsPaycalPeriod.vue'

export default {
  name: 'CbsPaycal',
  components: {
    CbsPaycalPeriod,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
    }
  },
  data() {
    return {
      currentPeriod: null,
      qryPeriods: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.currentPeriod = this.getConditionalFirstMonday()
      this.loadPeriods()
    },
    async loadPeriods() {
      const params = {
        keymode: 'sid',
        objectsid: 'cash_request',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        groupby: {
          isActive: true,
          groups: [
            {
              isActive: true,
              oper: { sid: 'day' },
              entity: { sid: 'date' },
            },
          ],
        },
        orderby: useCubus.orderby_date(),
        pageSize: 100000,
      }
      this.qryPeriods = await useCubus.loadQuery(this, params)
    },
    getConditionalFirstMonday() {
      const today = new Date() // Сегодняшняя дата
      const year = today.getFullYear() // Текущий год
      const currentMonth = today.getMonth() // Текущий месяц (0 - январь)
      const dayOfMonth = today.getDate() // Сегодняшний день месяца

      // Определяем месяц, для которого нужно найти первый понедельник
      const targetMonth = dayOfMonth < 25 ? currentMonth : currentMonth + 1

      // Вычисляем первый понедельник указанного месяца
      const res = this.getFirstMonday(year, targetMonth)

      const yr = res.getFullYear()
      const mn = String(res.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const dy = String(res.getDate()).padStart(2, '0') // Добавляем ведущий ноль

      // Форматируем строку в формате DD.MM.YYYY
      return `${dy}.${mn}.${yr}`
    },
    getFirstMonday(year, month) {
      // Если month больше 11, переходим на следующий год
      if (month > 11) {
        year++
        month = 0 // Январь следующего года
      }

      // Создаем дату первого дня месяца
      let date = new Date(year, month, 1)

      // Проверяем, является ли первый день месяца понедельником
      while (date.getDay() !== 1) {
        // Если нет, добавляем один день
        date.setDate(date.getDate() + 1)
      }

      // Возвращаем первый понедельник
      return date
    },
    isCurrentPeriodExists() {
      if (!this.qryPeriods) return false
      return this.qryPeriods.recordset.records.some(rec => rec.date.value.startsWith(this.currentPeriod))
    },
  },
}
</script>

<style scoped>

</style>
