<template>
<div>
  <!--<h1>CbsForm</h1>-->
  <asl-projects v-if="objectFull.object.sid === 'asl_projects'" :object-full="objectFull" />
  <cbs-user-menu v-else-if="objectFull.object.sid === 'cbsfrmusermenu'" :object-full="objectFull" />
  <cbs-indicator-access v-else-if="objectFull.object.sid === 'frmbudgetindicatoraccess'" :object-full="objectFull" />
  <cbs-budget-request v-else-if="objectFull.object.sid === 'frm_budget_request'" :object-full="objectFull" />
  <cbs-budget-request-access v-else-if="objectFull.object.sid === 'frm_budget_request_access'" :object-full="objectFull" />
  <cbs-budget-trip v-else-if="objectFull.object.sid === 'frm_budget_trip'" :object-full="objectFull" />
  <kbv-budget-indicator v-else-if="customForm() === 'kbvbudgetindicator'" :object-full="objectFull" />
  <cbs-calc-scheme v-else-if="objectFull.object.sid === 'cbs_calc_scheme'" :object-full="objectFull" />
  <cbs-cash-request v-else-if="objectFull.object.sid === 'cbs_cash_request'" :object-full="objectFull" />
  <cbs-paycal v-else-if="objectFull.object.sid === 'cbs_paycal'" :object-full="objectFull" />
  <cbs-debug v-if="session.user.sid === 'root'" :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import AslProjects from '@/cubus/components/cbs-form/custom-forms/als-projects/AslProjects.vue'
import KbvBudgetIndicator from '@/cubus/components/cbs-form/custom-forms/kbv-budget-indicator/KbvBudgetIndicator.vue'
import CbsUserMenu from '@/cubus/components/cbs-form/custom-forms/user-menu/CbsUserMenu.vue'
import CbsIndicatorAccess from '@/cubus/components/cbs-form/custom-forms/indicator-access/CbsIndicatorAccess.vue'
import CbsBudgetRequest from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudgetRequest.vue'
import CbsBudgetRequestAccess from '@/cubus/components/cbs-form/custom-forms/budget-request-access/CbsBudgetRequestAccess.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsBudgetTrip from '@/cubus/components/cbs-form/custom-forms/budget-trip/CbsBudgetTrip.vue'
import CbsCalcScheme from '@/cubus/components/cbs-form/custom-forms/calc-scheme/CbsCalcScheme.vue'
import CbsCashRequest from '@/cubus/components/cbs-form/custom-forms/cash-request/CbsCashRequest.vue'
import CbsPaycal from '@/cubus/components/cbs-form/custom-forms/payment-calendar/CbsPaycal.vue'

export default {
  name: 'CbsForm',
  components: {
    CbsPaycal,
    CbsCashRequest,
    CbsCalcScheme,
    CbsBudgetTrip,
    CbsBudgetRequestAccess,
    CbsBudgetRequest,
    KbvBudgetIndicator,
    CbsDebug,
    AslProjects,
    CbsUserMenu,
    CbsIndicatorAccess,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const session = useCubus.getSession()
    return {
      session,
    }
  },
  methods: {
    customForm() {
      if (this.objectFull && this.objectFull.params && this.objectFull.params.params) {
        const prm = this.objectFull.params.params.find(p => p.paramname === 'customform')
        if (prm) {
          return prm.paramvalue
        }
        return 'no_param'
      }
      return 'no_params'
    },
  },
}
</script>

<style scoped>

</style>
