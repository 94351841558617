<template>
  <div>
    <!-- toolbar -->
    <div v-if="isToolbar">
      <b-button-toolbar
        key-nav
        aria-label="Toolbar with button groups"
        class="demo-inline-spacing"
      >
        <b-button-group
          size="sm"
          class="cbs-inline-spacing mb-1"
        >
          <b-button
            variant="danger"
            @click="refreshTable"
          >
            <feather-icon icon="PlayIcon" />
            {{ t('Refresh') }}
          </b-button>
        </b-button-group>
        <b-button-group
          size="sm"
          class="cbs-inline-spacing mb-1"
        >
          <b-button
            ref="btnNew"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="isNew ? 'primary' : 'outline-primary'"
            :disabled="!isAccessAvailable('insert')"
            @click="toggleNewRecord"
          >
            <feather-icon icon="PlusIcon" />
            {{ t('New') }}
          </b-button>
        </b-button-group>
        <b-button-group
          size="sm"
          class="cbs-inline-spacing mb-1"
        >
          <b-button
            v-b-toggle="`collapse_search_${uuid}`"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Search')"
            :variant="activeTab === 'search' ? 'primary' : (search ? 'outline-success' : 'outline-primary')"
            @click="onSelectTab('search')"
          >
            <feather-icon icon="SearchIcon" />
            <!--{{ t('Search') }}-->
          </b-button>
          <b-button
            v-b-toggle="`collapse_filter_${uuid}`"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Filter')"
            :variant="activeTab === 'filter' ? 'primary' : (userFilter.node.isactive ? 'outline-success' : 'outline-primary')"
            @click="onSelectTab('filter')"
          >
            <feather-icon icon="FilterIcon" />
            <!--{{ t('Filter') }}-->
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Group')"
            :variant="activeTab === 'groupby' ? 'primary' : (groupby.isActive ? 'outline-success' : 'outline-primary')"
            @click="onSelectTab('groupby')"
          >
            <feather-icon icon="PackageIcon" />
            <!--{{ t('Group') }}-->
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Sort')"
            :variant="activeTab === 'orderby' ? 'primary' : (orderby.isActive ? 'outline-success' : 'outline-primary')"
            @click="onSelectTab('orderby')"
          >
            <feather-icon icon="BarChartIcon" />
            <!--{{ t('Sort') }}-->
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Fields')"
            :variant="activeTab === 'fields' ? 'primary' : 'outline-primary'"
            @click="onSelectTab('fields')"
          >
            <feather-icon icon="EyeIcon" />
            <!--{{ t('Fields') }}-->
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Views')"
            :variant="activeTab === 'view' ? 'primary' : 'outline-primary'"
            @click="onSelectTab('view')"
          >
            <feather-icon icon="SaveIcon" />
            <!--{{ t('Views') }}-->
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Export')"
            :variant="activeTab === 'export' ? 'primary' : 'outline-primary'"
            @click="onSelectTab('export')"
          >
            <feather-icon icon="DownloadIcon" />
            <!--{{ t('Export') }}-->
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="t('Settings')"
            :variant="activeTab === 'settings' ? 'primary' : 'outline-primary'"
            @click="onSelectTab('settings')"
          >
            <feather-icon icon="SettingsIcon" />
            <!--{{ t('Settings') }}-->
          </b-button>
          <b-button
            v-b-tooltip.hover.top="t('Reset fields')"
            :variant="'outline-primary'"
            @click="initFields()"
          >
            <feather-icon icon="BarChart2Icon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <!-- /toolbar -->

    <cbs-collapse :trigger="activeTab === 'search'">
      <div class="mb-1">
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="outline-primary"
              @click="runSearch"
            >
              <feather-icon icon="SearchIcon" /> Search
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            :ref="'search_' + uuid"
            v-model="search"
            placeholder="Search"
            @keyup.enter="runSearch"
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click="clearSearch"
            >
              <feather-icon icon="XIcon" /> Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </cbs-collapse>
    <cbs-collapse :trigger="activeTab === 'view'">
      <div class="mb-1 p-1 rounded border border-secondary">
        <cbs-table-view-param
          v-if="objectFull.object.sid !== 'tableviewparam'"
          :object="objectFull.object"
          :filter="userFilter"
          :orderby="orderby"
          :groupby="groupby"
          :fields="prmFields"
          @changepagesize="onChangePageSize"
          @applyparamready="onApplyParamReady"
        />
      </div>
    </cbs-collapse>
    <cbs-collapse :trigger="activeTab === 'settings'">
      <div class="mb-1 p-1 rounded border border-secondary">
        <b-row>
          <b-col cols="6">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Page size:"
              label-for="input-pagesize"
            >
              <b-form-input
                id="input-pagesize"
                v-model="perPage"
                type="number"
                placeholder="Page size"
                :formatter="formatterNumber"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <span class="text-secondary">Record count: </span><span class="text-primary">{{ recordCount }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Mode:"
            >
              <select
                v-model="mode"
                class="custom-select"
              >
                <option value="table">
                  Table
                </option>
                <option value="sheet">
                  Sheet
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col cols="6" />
        </b-row>
      </div>
    </cbs-collapse>
    <cbs-collapse :trigger="activeTab === 'filter'">
      <div class="mb-1 p-1 rounded border border-secondary">
        <b-row>
          <b-col>
            <cbs-filter
              :filter="userFilter"
              :fields="objAttrsFull()"
            />
          </b-col>
        </b-row>
      </div>
    </cbs-collapse>
    <cbs-collapse
      :trigger="activeTab === 'groupby'"
      class="mb-1 p-1 rounded border border-secondary"
    >
      <cbs-groupby
        :groupby="groupby"
        :fields="objAttrsFull()"
      />
    </cbs-collapse>
    <cbs-collapse :trigger="activeTab === 'orderby'">
      <div class="mb-1 p-1 rounded border border-secondary">
        <cbs-orderby
          :orderby="orderby"
          :fields="objAttrsFull()"
        />
      </div>
    </cbs-collapse>
    <cbs-collapse :trigger="activeTab === 'fields'">
      <div class="mb-1 p-1 rounded border border-secondary">
        <p>Fields:</p>
        <table>
          <tr
            v-for="(fld, fldIdx) in prmFields"
            :key="fldIdx"
          >
            <td>
              <feather-icon
                v-if="fldIdx > 0"
                icon="ArrowUpCircleIcon"
                size="16"
                class="text-primary cursor-pointer"
                @click="fieldUp(fldIdx)"
              />
              &nbsp;
            </td>
            <td>
              <feather-icon
                v-if="fldIdx < (fields.length-1)"
                icon="ArrowDownCircleIcon"
                size="16"
                class="text-primary cursor-pointer"
                @click="fieldDown(fldIdx)"
              />
              &nbsp;&nbsp;
            </td>
            <td>
              <feather-icon
                :icon="fld.visible ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
                class="cursor-pointer"
                :class="fld.visible ? 'text-success' : 'text-danger'"
                @click="fld.visible = ! fld.visible"
              />
              &nbsp;&nbsp;
            </td>
            <td>&nbsp;{{ fld.label }}</td>
          </tr>
        </table>
      </div>
    </cbs-collapse>
    <cbs-collapse :trigger="activeTab === 'export'">
      <cbs-export-query
        class="mb-1 p-1 rounded border border-secondary"
        :record="exportRecord()"
      />
    </cbs-collapse>

    <!-- new record -->
    <b-collapse :visible="isNew && newRecord != null">
      <div
        v-if="isNew && newRecord"
        style="border: 1px solid lightgray; margin-bottom:10px; border-radius: 5px;"
      >
        <cbs-file-upload
          v-if="objectFull.object.sid === 'file'"
          @save="addNewRecord"
        />
        <cbs-record-card
          v-else
          :ref="'cbsObjectTable_CbsRecordCard_new_' + uuid"
          :object-full="objectFull"
          title="New record"
          :prop-record="newRecord"
          :fields="realFields()"
          :row-index="0"
          mode="create"
          @close="closeNew"
          @save="onSave(newRecord, 'doCheck')"
          @saveandclear="onSave(newRecord, 'clearAfter')"
          @saveandclose="onSave(newRecord, 'closeAfter')"
          @saveandupdate="onSave(newRecord, 'updateAfter')"
        />
      </div>
    </b-collapse>
    <!-- /new record -->

    <!-- spinner -->
    <cbs-spinner
      v-if="isSpinner()"
      :error="loadingError"
      :message="loadingInfo"
    />
    <!-- /spinner -->

    <!-- table -->
    <b-table
      v-if="!isSpinner()"
      style="min-height: 250px;"
      responsive
      small
      :fields="visibleFields()"
      :items="records"
    >
      <!-- cell template -->
      <template
        v-for="(field, fldidx) in realFields()"
        v-slot:[`cell(${field.key})`]="data"
      >
        <div
          :key="fldidx"
          :style="tdStyle"
        >
          <div v-if="mode === 'sheet' && (true || data.item.isEditMode)">
            <cbs-sheet-cell
              :object-full="objectFull"
              :field="field"
              :cell="data.item[field.key]"
              :record="data.item"
              @updateCell="onChangeCell"
            />
          </div>
          <div v-else>
            <span
              v-if="field.datatype === 'refsid'"
              :key="fldidx"
              :title="data.value.title"
            >
              {{ (data.value.title && data.value.title !== '') ? data.value.title : data.value.value }}
            </span>
            <span
              v-else-if="data.value.title"
              :key="fldidx"
              :title="data.value.title + ' (' + data.value.value + ')'"
            >
              {{ data.value.title }}
              <span style="font-size: 0.8em;">({{ data.value.value }})</span>
            </span>
            <span
              v-else-if="typeof data.value.value == 'boolean'"
              :key="fldidx"
            >
              <feather-icon
                v-if="data.value.value === true"
                icon="CheckSquareIcon"
              />
              <feather-icon
                v-else
                icon="SquareIcon"
              />
            </span>
            <div
              v-else-if="field.datatype === 'double'"
              :key="fldidx"
              style="text-align: right;"
            >
              <span v-if="field.rendertype === 'percent'">{{ formatPercent(data.value.value) }}</span>
              <span v-else>{{ formatDouble(data.value.value) }}</span>
            </div>
            <div
              v-else-if="field.datatype === 'int'"
              :key="fldidx"
              style="text-align: right;"
            >
              {{ formatInteger(data.value.value) }}
            </div>
            <span
              v-else
              :key="fldidx"
              :title="valueTitle(data.value.value)"
            >
              {{ valueTitle(data.value.value) }}
            </span>
          </div>
        </div>
      </template>
      <!-- /cell template -->

      <!-- actions cell -->
      <template #cell(actions)="row">
        <div class="text-nowrap">
          <span
            v-if="isDetails(row)"
            class="mr-0"
          >
            <feather-icon
              icon="CornerLeftUpIcon"
              class="cursor-pointer text-warning"
              size="16"
              @click="toggleDetails(row)"
            />
          </span>
          &nbsp;
          <cbs-table-record-status :status="row.item.status" />

          <!-- row buttons -->
          <span v-if="rowButtons.length > 0">
            <span
              v-for="(btn, rowBtnIdx) in rowButtons"
              :key="rowBtnIdx"
            >
              &nbsp;
              <feather-icon
                :icon="btn.icon"
                class="cursor-pointer"
                :class="'text-' + btn.class"
                size="16"
                :title="btn.title"
                @click="onClickRowButton(btn,row)"
              />
            </span>
          </span>
          <!-- /row buttons -->

          <feather-icon
            icon="EditIcon"
            class="cursor-pointer ml-1"
            :class="(row.item._showDetails && row.item._detailsMode === 'card') ? 'text-primary' : ''"
            @click="toggleDetails(row)"
          />
          &nbsp;

          <!-- relation & drill buttons -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            class="cbs-dropdown"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                v-if="false"
                icon="Share2Icon"
                size="16"
                class="align-middle text-body"
              />
              <feather-icon
                icon="MoreVerticalIcon"
                size="14"
                class="align-middle text-body"
              />
            </template>

            <!-- drill -->
            <div v-if="isDrillable()">
              <cbs-groupby-operands
                hide
                @loaded="onLoadGroupbyOperands"
              />
              <b-dropdown-item
                v-if="isGroupbyValid()"
                @click="onShowRecords(row)"
              >
                <feather-icon icon="PlusSquareIcon" />&nbsp;&nbsp;{{ t('Show Records') }}
              </b-dropdown-item>
              <b-dropdown-group
                v-if="groupbyOpers"
                header="-- DRILLS --"
              >
                <b-dropdown-item
                  v-for="(drl, drlIndex) in drillList()"
                  :key="drlIndex"
                  @click="onClickDrill(row,drl)"
                >
                  {{ drl.attr.label }} <small
                    v-if="drl.oper.sid !== 'group'"
                    class="text-muted"
                  >({{ drl.oper.name }})</small>
                </b-dropdown-item>
                <b-dropdown-divider />
              </b-dropdown-group>
            </div>

            <b-dropdown-group v-if="objectFull.children.length > 0" header="-- CHILDREN --">
              <b-dropdown-item v-for="ch in objectFull.children" :key="'child_' + ch.object.id" @click="onClickChild(row, ch)">
                {{ ch.object.name }}
              </b-dropdown-item>
            </b-dropdown-group>

            <b-dropdown-group
              v-if="objectFull.relations.length > 0"
              header="-- RELATIONS --"
            >
              <b-dropdown-item
                v-for="(rel, relIndex) in objectFull.relations"
                :key="relIndex"
                @click="onClickRelation(row,rel)"
              >
                {{ rel.objectname }} <small class="text-muted">({{ rel.entityname }})</small>
                <!--<span class="align-middle ml-50">{{ rel.name }}</span>-->
              </b-dropdown-item>
              <b-dropdown-divider />
            </b-dropdown-group>

            <b-dropdown-group v-if="isAccessAvailable('insert')">
              <b-dropdown-item
                variant="primary"
                @click="onDuplicate(row)"
              >
                <feather-icon icon="CopyIcon" />&nbsp;&nbsp;{{ t('Duplicate') }}
              </b-dropdown-item>
            </b-dropdown-group>

            <b-dropdown-group>
              <b-dropdown-item
                v-if="isDeleteAvailable() && !isRowDeleted(row)"
                variant="warning"
                @click="onDelete(row)"
              >
                <feather-icon icon="TrashIcon" />&nbsp;&nbsp;{{ t('Delete') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isRestoreAvailable && isRowDeleted(row)"
                variant="success"
                @click="onRestore(row)"
              >
                <feather-icon icon="CornerUpLeftIcon" />&nbsp;&nbsp;{{ t('Restore') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isAccessAvailable('remove') && isRowDeleted(row)"
                variant="danger"
                @click="onRemove(row)"
              >
                <feather-icon icon="Trash2Icon" />&nbsp;&nbsp;{{ t('Remove') }}
              </b-dropdown-item>
            </b-dropdown-group>

          </b-dropdown>
          <!-- /relation & drill buttons -->

          <b-dropdown
            v-if="false"
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="14"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="mode === 'sheet'"
              :variant="row.item.isEditMode ? 'success' : 'primary'"
              @click="toggleEditMode(row)"
            >
              <feather-icon icon="Edit3Icon" />
              <span class="align-middle ml-50">Edit Mode</span>
            </b-dropdown-item>
            <b-dropdown-item
              variant="primary"
              @click="showDetails(row)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(btn, rowDropdownBtnIndex) in rowDropdownButtons"
              :key="rowDropdownBtnIndex"
              @click="onClickRowDropdownButton(btn,row)"
            >
              <feather-icon :icon="btn.icon" /> {{ btn.title }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isDeleteAvailable && !isRowDeleted(row)"
              variant="warning"
              @click="onDelete(row)"
            >
              <feather-icon icon="TrashIcon" />&nbsp;&nbsp;Delete
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isRestoreAvailable && isRowDeleted(row)"
              variant="warning"
              @click="onRestore(row)"
            >
              <feather-icon icon="CornerUpLeftIcon" />&nbsp;&nbsp;Restore
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isAccessAvailable('remove') && isRowDeleted(row)"
              variant="warning"
              @click="onRemove(row)"
            >
              <feather-icon icon="Trash2Icon" />&nbsp;&nbsp;Remove
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <!-- /actions cell -->

      <!-- record details -->
      <template #row-details="row">
        <cbs-collapse :trigger="isDetails(row)">
          <div
            v-if="isDetails(row)"
            class="mb-1 p-0 rounded border border-secondary"
          >
            <div v-if="isCard(row)" style="width: 75vw;">
              <cbs-record-card
                v-if="isCard(row)"
                :object="object"
                :object-full="objectFull"
                :fields="realFields()"
                :prop-record="row.item"
                :row-index="row.index"
                title="Record card"
                mode="edit"
                @close="row.toggleDetails"
                @save="onSave(row.item)"
                @saveandclose="onSave(row.item, 'closeAfter')"
              />
            </div>

            <cbs-ctrl-relation
              v-if="isRelation(row)"
              :objectid="row.item._relation.objectid"
              :filter="row.item._relationFilter"
              @close="row.toggleDetails"
            />

            <cbs-ctrl-relation v-else-if="row.item._detailsMode === 'child'"
                               :objectid="row.item._child.object.id"
                               :filter="row.item._childFilter"
                               @close="row.toggleDetails"
            />

            <div v-if="detailMode(row) === 'drill'" class="m-1">
              <cbs-object-table
                :object-full="objectFull"
                :filter="rowDrillFilter(row)"
                :prop-groupby="rowDrillGroupby(row)"
                :prop-orderby="rowDrillOrderby(row)"
                @close="row.toggleDetails"
              />
            </div>
          </div>
        </cbs-collapse>
      </template>
      <!-- /record details -->

    </b-table>
    <!-- /table -->

    <!-- pagination -->
    <b-row v-if="recordCount > perPage">
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="recordCount"
          :per-page="perPage"
          first-number
          last-number
          align="right"
          class="mt-2"
        >
          <template #first-text>
            <span class="text-default">
              <feather-icon icon="ChevronsLeftIcon" />
              First
            </span>
          </template>
          <template #prev-text>
            <span class="text-default">
              <feather-icon icon="ChevronLeftIcon" />
              Prev
            </span>
          </template>
          <template #next-text>
            <span class="text-default">
              Next
              <feather-icon icon="ChevronRightIcon" />
            </span>
          </template>
          <template #last-text>
            <span class="text-default">
              Last
              <feather-icon icon="ChevronsRightIcon" />
            </span>
          </template>
          <template #ellipsis-text>
            <div>
              ...
            </div>
          </template>
          <template #page="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <span v-else>{{ page }}</span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <!-- /pagination -->

    <!-- remove modal -->
    <div>
      <b-button
        v-show="false"
        :ref="'btmRemove' + uuid"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal="'modal_remove' + uuid"
        variant="outline-primary"
      >
        Login Form
      </b-button>
      <b-modal
        :id="'modal_remove' + uuid"
        cancel-variant="outline-warning"
        ok-title="Yes"
        cancel-title="No"
        centered
        title="Remove record"
        @ok="onOkRemove"
        @cancel="onCancelRemove"
        @close="onCloseRemove"
      >
        Are you sure to remove record?
      </b-modal>
    </div>
    <!-- /remove modal -->

    <!-- record modal -->
    <div>
      <b-button
        v-show="false"
        :ref="'btn_record_' + uuid"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal="'modal_record_' + uuid"
        variant="outline-primary"
      >
        Record Form
      </b-button>
      <b-modal
        :id="'modal_record_' + uuid"
        scrollable
        cancel-variant="outline-warning"
        ok-title="Yes"
        cancel-title="No"
        centered
        title="Record card"
        size="xl"
        @ok="onOkRemove"
        @cancel="onCancelRemove"
        @close="onCloseRemove"
      >
        <cbs-collapse :trigger="isNewRecord()">
          <cbs-file-upload
            v-if="objectFull.object.sid === 'file'"
            @save="addNewRecord"
          />
          <cbs-record-card
            v-else
            :ref="'cbsObjectTable_CbsRecordCard_new_' + uuid"
            :object-full="objectFull"
            title="New record"
            :prop-record="newRecord"
            :fields="realFields()"
            :row-index="0"
            mode="create"
            @close="closeNew"
            @save="onSave(newRecord, 'doCheck')"
            @saveandclear="onSave(newRecord, 'clearAfter')"
            @saveandclose="onSave(newRecord, 'closeAfter')"
          />
        </cbs-collapse>
      </b-modal>
    </div>
    <!-- /record modal -->

    <cbs-debug
      v-if="isAdmin()"
      :context="this"
    />
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,
  BButton,
  BButtonToolbar,
  BButtonGroup,
  VBToggle,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BFormGroup,
  BPagination,
  BInputGroupAppend,
  BDropdownDivider,
  BDropdownGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsRecordCard from '@/cubus/components/object/CbsRecordCard.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsFilter from '@/cubus/components/filter/CbsFilter.vue'
import CbsFileUpload from '@/cubus/components/file/CbsFileUpload.vue'
import CbsGroupby from '@/cubus/components/groupby/CbsGroupby.vue'
import CbsOrderby from '@/cubus/components/orderby/CbsOrderby.vue'
import CbsTableViewParam from '@/cubus/components/table-view-param/CbsTableViewParam.vue'
import CbsTableRecordStatus from '@/cubus/components/query/CbsTableRecordStatus.vue'
import CbsCtrlRelation from '@/cubus/components/relation/CbsCtrlRelation.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsExportQuery from '@/cubus/components/export-query/CbsExportQuery.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsSheetCell from '@/cubus/components/sheet/CbsSheetCell.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store/index'
import CbsGroupbyOperands from '@/cubus/components/groupby/CbsGroupbyOperands.vue'
import CbsSpinner from '@/cubus/components/spinner/CbsSpinner.vue'

export default {
  name: 'CbsObjectTable',
  components: {
    CbsSpinner,
    CbsGroupbyOperands,
    CbsCollapse,
    CbsExportQuery,
    CbsCtrlRelation,
    CbsTableViewParam,
    CbsGroupby,
    CbsFileUpload,
    CbsFilter,
    // CbsObject: () => import('@/cubus/components/object/CbsObject.vue'),
    CbsRecordCard,
    BTable,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BCollapse,
    BDropdown,
    BDropdownGroup,
    BDropdownItem,
    BDropdownDivider,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    CbsOrderby,
    BInputGroupAppend,
    CbsTableRecordStatus,
    CbsDebug,
    CbsSheetCell,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    rowButtons: {
      type: Array,
      default: () => ([]),
    },
    rowDropdownButtons: {
      type: Array,
      default: () => ([]),
    },
    domainsid: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    isToolbar: {
      type: Boolean,
      default: true,
    },
    objectFull: {
      type: Object,
      default: null,
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
    propGroupby: {
      type: Object,
      default: () => ({
        isActive: false,
        groups: [],
      }),
    },
    propOrderby: {
      type: Object,
      default: () => ({
        isActive: false,
        sorts: [],
      }),
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
    }
  },
  emits: [
    'close',
    'clickrowbutton',
    'loaded',
  ],
  data() {
    return {
      object: {},
      // access: {},
      query: {},
      thread: {},
      records: [],
      fields: [],
      isNew: false,
      isSearch: false,
      newRecord: null,
      search: null,
      isRefreshQuery: false,
      rowToRemove: {},
      isDebug: false,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50],
      isSettings: false,
      recordCount: -1,
      isFilter: false,
      userFilter: {
        node: {
          isactive: false,
          isexpanded: false,
          name: 'Root Node',
          oper: { id: 1, sid: 'and', name: 'AND' },
          nodes: [],
          conds: [],
        },
      },
      simpleFilter: {
        node: {
          isactive: false,
          isexpanded: true,
          name: 'Simple Filter Node',
          oper: { id: 1, sid: 'and', name: 'AND' },
          nodes: [],
          conds: [],
        },
      },
      isGroupby: false,
      groupby: {
        isActive: false,
        groups: [],
      },
      numberFormatConfig: {
        decimal: '.',
        separator: ',',
        prefix: '$ ',
        suffix: ' #',
        precision: 2,
        masked: false,
      },
      isOrderby: false,
      orderby: {
        isActive: false,
        sorts: [],
      },
      isView: false,
      isFields: false,
      prmFields: [],
      uuid: null,
      isExport: false,
      exportToken: null,
      exportUrl: null,
      exportFilename: null,
      mode: 'table',
      activeTab: null,
      groupbyOpers: null,
      loadingStatus: 'idle',
      loadingError: null,
      loadingInfo: null,
      isInitFields: false,
      isApplyInProgress: false,
      viewMode: 'table',
    }
  },
  computed: {},
  watch: {
    currentPage(newValue, oldValue) {
      // console.log('watch currentPage', oldValue, newValue)
      this.changePage()
    },
    propGroupby(newValue, oldValue) {
      // Prop changed, perform logic here
      // console.log('Prop changed in child:', newValue);
      console.log('watch propGroupby', newValue, oldValue)
      this.initGroupby()
      this.loadQuery('init')
    },
    propOrderby(newValue, oldValue) {
      // Prop changed, perform logic here
      // console.log('Prop changed in child:', newValue);
      this.initOrderby()
      this.loadQuery('init')
    },
    groupby(newValue, oldValue) {
      // console.log('watch groupby', newValue, oldValue)
      if (!this.isApplyInProgress) {
        // console.log('watch groupby')
        this.isInitFields = true
      }
    },
    'groupby.isActive'(newValue, oldValue) {
      // console.log('watch groupby.isActive', newValue, oldValue)
      if (!this.isApplyInProgress) {
        // console.log('watch groupby.isActive')
        this.isInitFields = true
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
      this.initGroupby()
      this.initOrderby()
      this.loadQuery('init')
    },
    initGroupby() {
      if (this.propGroupby && this.propGroupby !== {}) {
        this.groupby = this.propGroupby
      }
    },
    initOrderby() {
      if (this.propOrderby && this.propOrderby !== {}) {
        this.orderby = this.propOrderby
      }
    },
    loadQuery(mode) {
      // console.log('load data')
      this.loadingStatus = 'loading'
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'query',
          param: {
            objectid: this.objectFull.object.id,
            domainsid: this.domainsid,
            filter: this.filter,
            userfilter: this.userFilter,
            limit: Number(this.perPage),
            offset: this.perPage * (this.currentPage - 1),
            groupby: this.groupby,
            orderby: this.orderby,
            search: this.search,
            mode,
          },
        },
      })
        .then(response => {
          // console.log('query response', response)
          // this.query = response
          if (response.data.thread) this.loadThreadStatus(response.data.thread, mode)
        })
        .catch(errQry => {
          console.log('query error', errQry)
        })
    },
    delayThreadStatus(threadName, mode) { setTimeout(() => this.loadThreadStatus(threadName, mode), 500) },
    loadThreadStatus(threadName, mode) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data.thread && response.data.thread.status === 'done' && response.data.thread.result) {
            this.query = response.data.thread.result.query
            this.initTable(mode)
          } else if (response.data.thread && response.data.thread.status === 'error') {
            this.loadingError = response.data.thread.error
            this.loadingStatus = 'error'
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayThreadStatus(threadName)
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    initTable(mode) {
      // console.log('init Table', mode)
      this.records = this.query.recordset.records
      this.fields = this.query.columns
      // if (this.prmFields.length === 0) this.initFields()
      // if (mode !== 'page') this.initFields()
      if (mode !== 'applyprm') {
        if (mode === 'init' || this.groupby.isActive || this.prmFields.length === 0 || this.isInitFields) this.initFields()
      }
      this.recordCount = this.query.recordcount
      this.loadingStatus = 'idle'
      this.isApplyInProgress = false
      this.$emit('loaded', this.query)
      // console.log('init Table done', mode)
    },
    initFields() {
      // console.log('initFields start')
      // eslint-disable-next-line no-return-assign
      this.hiddenFields.forEach(f => {
        const fldv = this.fields.find(ff => ff.key === f)
        if (fldv && !this.groupby.isActive) fldv.visible = false
        // this.fields.find(fld => fld.key === f).visible = false
      })
      this.prmFields = this.fields.map(fld => ({ key: fld.key, label: fld.label, visible: fld.visible }))
      this.isInitFields = false
      // console.log('initFields done')
    },
    refreshStop(cardName) {
      this.init()
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 1000)
    },
    closeCard() {
      // console.log('cbs object : close card')
      this.$emit('close')
    },
    realFields() {
      return this.fields.filter(fld => fld.key !== 'actions')
    },
    toggleNewRecord() {
      if (this.isNew) {
        this.clearNew()
      } else {
        if (this.objectFull.object.sid !== 'file') {
          this.loadNewRecord()
        }
        this.isNew = true
      }
    },
    clearNew() {
      this.isNew = false
      this.newRecord = null
    },
    toggleSearch() {
      this.isSearch = !this.isSearch
    },
    loadNewRecord() {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'newrecord',
          param: {
            objectid: this.objectFull.object.id,
            filter: this.filter,
            userfilter: this.userFilter,
          },
        },
      })
        .then(response => {
          // console.log('newRecord response', response)
          if (response.data.thread) {
            this.threadNewRecord(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('newRecord error', error)
          useCubus.toastError(this, error)
        })
    },
    threadNewRecord(threadName) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          // console.log('thread response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                console.log('new record result', response.data.thread.result)
                this.newRecord = response.data.thread.result.record.record
              } else {
                this.delayNewRecord(threadName)
              }
            }
          } else this.delayNewRecord(threadName)
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayNewRecord(thread) { setTimeout(() => this.threadNewRecord(thread), 500) },
    closeNew() {
      this.$refs.btnNew.click()
    },
    onSave(record, caller) {
      // console.log('caller', caller)
      if (this.hasFileEntity()) {
        this.saveRecordFile(record, caller)
      } else {
        this.saveRecord(record, caller)
      }
    },
    saveRecordFile(record, caller) {
      console.log('save file', record)
      const formData = new FormData()
      const filekeys = []
      this.fields.forEach(f => {
        if (record[f.key] && record[f.key].file) {
          const key = `file_${f.entityid}`
          filekeys.push(key)
          formData.append(key, record[f.key].file)
        }
      })
      formData.append('query', JSON.stringify({
        method: 'saverecord',
        param: {
          objectid: this.objectFull.object.id,
          record,
          attachments: filekeys.length,
          filekeys,
        },
      }))
      useJwt.upload(
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
        .then(response => {
          console.log('save file response', response)
          if (response.data) {
            if (response.data.thread) {
              this.threadSaveRecordFile(response.data.thread, record, caller)
            } else if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else {
              useCubus.toastError(this, 'No thread name provided.')
            }
          } else {
            useCubus.toastError(this, 'No data provided.')
          }
        })
        .catch(error => {
          console.log('save file error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecordFile(thread, record, caller) {
      setTimeout(() => this.threadSaveRecordFile(thread, record, caller), 1000)
    },
    threadSaveRecordFile(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecordFile response', response)
          if (response.data && response.data.thread && response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record, caller)
          } else if (response.data && response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            this.delaySaveRecordFile(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    saveRecord(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objectFull.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record, caller)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadSaveRecord(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record, caller)
          } else {
            this.delaySaveRecord(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record, caller) {
      setTimeout(() => this.threadSaveRecord(thread, record, caller), 1000)
    },
    afterSave(initRec, savedRec, caller) {
      savedRec.status = 'saved'
      if (initRec.id.value === null) {
        this.addNewRecord(savedRec)
        if (caller === 'closeAfter' || caller === 'save') {
          this.toggleNewRecord()
        } else if (caller === 'clearAfter') {
          this.loadNewRecord()
          this.$refs[`cbsObjectTable_CbsRecordCard_new_${this.uuid}`].savingDone()
        } else if (caller === 'doCheck') {
          console.log('doCheck')
          this.$refs[`cbsObjectTable_CbsRecordCard_new_${this.uuid}`].savingDone()
        } else if (caller === 'updateAfter') {
          this.newRecord = savedRec
        } else {
          this.toggleNewRecord()
        }
      } else {
        this.fields.forEach(fld => {
          initRec[fld.key] = savedRec[fld.key]
        })
        initRec.status = 'saved'
      }
    },
    addNewRecord(rec) {
      this.records.push(rec)
      // this.$refs.btnNew.click()
    },
    getRecordClone(record) {
      return JSON.parse(JSON.stringify(record))
      // console.log('clone1', clone)
      // eslint-disable-next-line no-restricted-syntax
      /*
      for (const prop in clone) {
        if (!prop.startsWith('_')) {
          // console.log('key', prop)
          clone[prop].init = clone[prop].value
        }
      }
      return JSON.parse(JSON.stringify(clone))
      */
    },
    onClickRowDropdownButton(btn, index) {
      console.log('clickRowDropdownButton', btn, index)
    },
    onClickRowButton(btn, index) {
      // console.log('clickRowButton', btn, index)
      this.$emit('clickRowButton', btn, index)
    },
    isDeleteAvailable() {
      return this.objectFull.access && this.objectFull.access.delete && (this.objectFull.access.delete === 'grant' || this.objectFull.access.delete === 'withgrant')
    },
    isRestoreAvailable() {
      const { access } = this.objectFull
      return access && access.restore && (access.restore === 'grant' || access.restore === 'withgrant')
    },
    isAccessAvailable(permissiontype) {
      return this.objectFull.access && this.objectFull.access[permissiontype]
          && (this.objectFull.access[permissiontype] === 'grant' || this.objectFull.access[permissiontype] === 'withgrant')
    },
    onDelete(row) {
      // console.log('onDelete', row)
      this.deleteRecord(row)
    },
    onRemove(row) {
      this.rowToRemove = row
      this.$refs[`btmRemove${this.uuid}`].click()
      // this.removeRecord(row)
    },
    onRestore(row) {
      console.log('onRestore', row)
      this.restoreRecord(row)
    },
    deleteRecord(row) {
      // console.log('restore record', row)
      row.item.status = 'changed'
      row.item.del.value = true
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'deleterecord',
          param: {
            objectid: this.objectFull.object.id,
            record: row.item,
          },
        },
      })
        .then(response => {
          console.log('delete record response', response)
          if (response.data) {
            if (response.data.thread) this.delayThreadDelete(response.data.thread, row)
            if (response.data.error) this.toast(response.data.error)
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
        })
    },
    removeRecord(row) {
      row.item.status = 'changed'
      row.item.del.value = true
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'removerecord',
          param: {
            objectid: this.objectFull.object.id,
            record: row.item,
          },
        },
      })
        .then(response => {
          console.log('remove record response', response)
          if (response.data) {
            if (response.data.thread) {
              this.delayThreadRemove(response.data.thread, row)
            } else if (response.data.error) {
              this.toast(response.data.error)
            }
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
        })
    },
    restoreRecord(row) {
      console.log('restore record', row)
      row.item.status = 'changed'
      row.item.del.value = false
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'restorerecord',
          param: {
            objectid: this.objectFull.object.id,
            record: row.item,
          },
        },
      })
        .then(response => {
          console.log('restore record response', response)
          if (response.data) {
            if (response.data.thread) this.delayThreadRestore(response.data.thread, row)
            if (response.data.error) this.toast(response.data.error)
          }
        })
        .catch(error => {
          console.log('deleterecord error', error)
        })
    },
    delayThreadDelete(threadName, row) {
      setTimeout(() => this.loadThreadDelete(threadName, row), 500)
    },
    delayThreadRemove(threadName, row) { setTimeout(() => this.loadThreadRemove(threadName, row), 500) },
    delayThreadRestore(threadName, row) {
      setTimeout(() => this.loadThreadRestore(threadName, row), 500)
    },
    loadThreadDelete(threadName, row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadDelete response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done'
                  && response.data.thread.result && response.data.thread.result.record) {
                this.records.splice(row.index, 1, response.data.thread.result.record)
              } else {
                this.delayThreadDelete(threadName, row)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    loadThreadRemove(threadName, row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadRemove response', response)
          if (response.data && response.data.thread) {
            if (response.data.thread.error) {
              this.toast(response.data.thread.error)
            } else if (response.data.thread.status === 'done') {
              this.records.splice(row.index, 1)
            } else {
              this.delayThreadRemove(threadName, row)
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    loadThreadRestore(threadName, row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadRestore response', response)
          if (response.data) {
            if (response.data.error) {
              this.toast(response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done'
                  && response.data.thread.result && response.data.thread.result.record) {
                this.records.splice(row.index, 1, response.data.thread.result.record)
              } else {
                this.delayThreadRestore(threadName, row)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    toast(error) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Something went wrong',
          icon: 'CoffeeIcon',
          variant: 'warning',
          text: error,
        },
      })
      if (error === 'Session not found.') {
        useCubus.logout()
        this.$router.push({ name: 'auth-login' })
      }
    },
    isRowDeleted(row) {
      return row.item && row.item.del && row.item.del.value === true
    },
    onOkRemove() {
      this.removeRecord(this.rowToRemove)
    },
    onCancelRemove() {
      this.rowToRemove = {}
    },
    onCloseRemove() {
      this.rowToRemove = {}
    },
    isAdmin() {
      const sess = this.$store.getters['cubus-store/SESSION']
      return sess && sess.user && sess.user.sid && (sess.user.sid === 'root' || sess.user.sid === 'batman')
    },
    toggleDetails(row) {
      if (this.mode === 'sheet') {
        this.toggleEditMode(row)
        // eslint-disable-next-line no-underscore-dangle
      } else if (row.item._showDetails) {
        // eslint-disable-next-line no-underscore-dangle,no-param-reassign
        row.item._showDetails = false
      } else {
        this.showDetails(row)
      }
    },
    toggleEditMode(row) {
      // console.log('row.item', row.item)
      if (row.item.isEditMode) {
        // eslint-disable-next-line no-param-reassign
        row.item.isEditMode = false
        this.setEditMode(row, false)
      } else {
        this.$set(row.item, 'isEditMode', true)
        this.setEditMode(row, true)
      }
    },
    setEditMode(row, crit) {
      // eslint-disable-next-line no-return-assign,no-param-reassign
      Object.keys(row.item).forEach(e => {
        if (e !== 'isEditMode') {
          this.$set(row.item[e], 'isEditMode', crit)
        }
      })
    },
    showDetails(row) {
      // console.log('showDetails row', row)
      // console.log('showDetails record', this.records[row.index])
      this.$set(row.item, '_detailsMode', 'card')
      this.$set(row.item, '_showDetails', true)
    },
    isDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row.item._showDetails
    },
    isCard(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row.item._detailsMode === 'card'
    },
    isRelation(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row.item._detailsMode === 'relation'
    },
    detailMode(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row.item._detailsMode
    },
    onClickRelation(row, rel) {
      console.log('click relation', row, rel)
      this.$set(row.item, '_relation', rel)
      this.$set(row.item, '_relationFilter', this.relationFilter(row, rel))
      this.$set(row.item, '_detailsMode', 'relation')
      if (row.item._showDetails === undefined) {
        this.$set(row.item, '_showDetails', true)
      } else {
        row.item._showDetails = true
      }
    },
    relationFilter(row, rel) {
      // console.log('row', row)
      // console.log('rel', rel)
      const key = rel.identityid && rel.identityid !== 0 ? this.fields.find(f => f.entityid === rel.identityid).key : 'id'
      // console.log('key', key)
      return {
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entity' }, value: `${rel.entityid}` },
                { type: { sid: 'value' }, value: `${row.item[key].value}` },
              ],
            },
          ],
        },
      }
    },
    toggleSettings() {
      this.isSettings = !this.isSettings
    },
    toggleFilter() {
      this.isFilter = !this.isFilter
    },
    refreshTable(mode) {
      console.log('refresh Table', mode)
      this.activeTab = null
      this.records = []
      this.currentPage = 1
      this.loadQuery(mode)
    },
    formatterNumber(value) {
      return Number(value)
    },
    changePage() {
      this.loadQuery('page')
    },
    objAttrs() {
      return this.fields.filter(f => f.entityid).map(f => this.attrToField(f))
      /*
      return this.fields.filter(f => f.entityid).map(f => ({
        title: f.label,
        value: f.entityid,
        datatype: f.datatype,
        attributeid: f.attributeid,
        entitysid: f.entitysid,
        parententityid: f.parententityid,
        refobjectid: f.refobjectid,
      }))
      */
    },
    objAttrsFull() {
      return this.objectFull.attributes.map(f => this.attrToField(f))
    },
    attrToField(f) {
      return {
        title: f.label,
        value: f.entityid,
        datatype: f.datatype,
        attributeid: f.attributeid,
        entitysid: f.entitysid,
        parententityid: f.parententityid,
        refobjectid: f.refobjectid,
      }
    },
    hasFileEntity() {
      return this.fields.some(f => f.datatype === 'file')
    },
    toggleGroupby() {
      this.isGroupby = !this.isGroupby
    },
    formatDouble(value) {
      return useCubus.formatNumber(value, null)
    },
    formatPercent(value) {
      return useCubus.formatPercent(value)
    },
    formatInteger(value) {
      return useCubus.formatNumber(value, { precision: 0 })
    },
    onChangePageSize(ps) {
      console.log('onChangePageSize', ps)
      this.perPage = ps
    },
    visibleFields() {
      // return this.fields.filter(fld => fld.key && fld.visible)
      const flds = this.prmFields
        .filter(prm => prm.visible)
        .filter(prm => this.fields.some(fld => fld.key === prm.key))
        .map(prm => this.fields.find(fld => fld.key === prm.key))
        .map(fld => ({ ...fld, tdAttr: { 'vertical-align': 'top', 'background-color': 'blue' }, class: 'cbstbl' }))
      // console.log('fields', flds)
      return flds
    },
    fieldUp(idx) {
      const tmp1 = JSON.parse(JSON.stringify(this.prmFields[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.prmFields[idx - 1]))
      this.$set(this.prmFields, idx, tmp2)
      this.$set(this.prmFields, idx - 1, tmp1)
    },
    fieldDown(idx) {
      const tmp1 = JSON.parse(JSON.stringify(this.prmFields[idx]))
      const tmp2 = JSON.parse(JSON.stringify(this.prmFields[idx + 1]))
      this.$set(this.prmFields, idx, tmp2)
      this.$set(this.prmFields, idx + 1, tmp1)
    },
    runSearch() {
      this.refreshTable()
    },
    clearSearch() {
      this.search = null
    },
    exportRecord() {
      return {
        fldlimit: { title: '', value: 100000 },
        fldoffset: { title: '', value: 0 },
        jsonfilter: { title: '', value: this.consFilter() },
        jsongroupby: { title: '', value: this.groupby },
        jsonorderby: { title: '', value: this.orderby },
        objectid: {
          sid: this.objectFull.object.sid,
          title: this.objectFull.object.name,
          value: this.objectFull.object.id,
        },
      }
    },
    onApplyParam() {
      this.refreshTable()
    },
    onApplyParamReady(prm) {
      this.isApplyInProgress = true
      this.userFilter = prm.filter
      this.groupby = prm.groupby
      this.orderby = prm.orderby
      this.pagesize = prm.pagesize
      this.fields = prm.fields
      this.refreshTable('applyprm')
    },
    isNewRecord() {
      return !!(this.isNew && this.newRecord)
    },
    isGroupbyValid() {
      return this.groupby && this.groupby.isActive && this.groupby.groups && this.groupby.groups.length > 0 && this.groupby.groups.some(g => g.isActive)
    },
    isDrillable() {
      return this.isGroupbyValid()
    },
    drillList() {
      const arr = this.objectFull.attributes.map(a => {
        if (a.datatype === 'datetime') {
          if (this.groupby.groups.some(g => g.isActive && g.entity && g.entity.value === a.entityid && g.oper.sid === 'day')) {
            return []
          }
          if (this.groupby.groups.some(g => g.isActive && g.entity && g.entity.value === a.entityid && g.oper.sid === 'month')) {
            return [
              { attr: a, oper: this.groupbyOper('day') },
            ]
          }
          if (this.groupby.groups.some(g => g.isActive && g.entity && g.entity.value === a.entityid && g.oper.sid === 'year')) {
            return [
              { attr: a, oper: this.groupbyOper('month') },
              { attr: a, oper: this.groupbyOper('day') },
            ]
          }
          return [
            { attr: a, oper: this.groupbyOper('year') },
            { attr: a, oper: this.groupbyOper('month') },
            { attr: a, oper: this.groupbyOper('day') },
          ]
        }
        return { attr: a, oper: this.groupbyOper('group') }
      })
      return arr.flat().filter(drl => !this.isDrillInGroupby(drl))
    },
    isDrillInGroupby(drl) {
      return this.groupby && this.groupby.groups && this.groupby.groups.some(g => g.isActive && g.entity && g.entity.value === drl.attr.entityid && g.oper.sid === drl.oper.sid)
    },
    onClickDrill(row, drl) {
      // console.log('onClickDrill', row, drl)
      const grb = this.getRowDrillGroupby(drl)
      // console.log('grb', grb)
      const flt = this.getRowDrillFilter(row)
      const detailsDrill = {
        drill: drl,
        groupby: grb,
        filter: flt,
        orderby: this.orderby,
      }
      this.$set(row.item, '_drill', detailsDrill)
      this.$set(row.item, '_detailsMode', 'drill')
      // eslint-disable-next-line no-underscore-dangle
      if (row.item._showDetails === undefined) {
        this.$set(row.item, '_showDetails', true)
      } else {
        // eslint-disable-next-line no-underscore-dangle,no-param-reassign
        row.item._showDetails = true
      }
    },
    rowDrillGroupby(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row.item._drill.groupby
    },
    rowDrillOrderby(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row.item._drill.orderby
    },
    rowDrillFilter(row) {
      // eslint-disable-next-line no-underscore-dangle
      return row.item._drill.filter
    },
    getRowDrillGroupby(drl) {
      const attr = this.objectFull.attributes.find(a => a.entityid === drl.attr.entityid)
      const grb = JSON.parse(JSON.stringify(this.groupby))
      const gr = grb.groups.find(g => g.entity.value === drl.attr.entityid && g.oper.sid === drl.oper.sid)
      if (gr) {
        gr.isActive = true
      } else {
        grb.groups.push({
          isActive: true,
          name: 'drill',
          entity: { value: drl.attr.entityid, title: drl.attr.label },
          oper: drl.oper,
        })
      }
      // console.log('grb after push', grb.groups.map(g => g.oper.sid).toString())
      if (attr.datatype === 'datetime') {
        if (drl.oper.sid === 'month') {
          if (grb.groups.some(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'year')) {
            grb.groups.splice(grb.groups.findIndex(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'year'), 1)
          }
        } else if (drl.oper.sid === 'day') {
          if (grb.groups.some(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'month')) {
            grb.groups.splice(grb.groups.findIndex(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'month'), 1)
          }
          // console.log('grb after slice month', grb.groups.map(g => `${g.oper.sid}.${g.entity.value},`).toString())
          // const idx = grb.groups.findIndex(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'year')
          // console.log(`year index = ${idx}`)
          if (grb.groups.some(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'year')) {
            grb.groups.splice(grb.groups.findIndex(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'year'), 1)
          }
          // console.log('grb after slice year', grb.groups.map(g => g.oper.sid).toString())
        }
      }
      // console.log('grb after slice', grb.groups.map(g => g.oper.sid).toString())
      return grb
    },
    getRowDrillFilter(row) {
      const flt = JSON.parse(JSON.stringify(store.getters['cubus-store/BLANK_FILTER']))
      flt.node.isactive = true
      const drillNode = {
        isactive: true,
        isexpanded: false,
        name: 'drill node',
        oper: { id: 1, sid: 'and', name: 'AND' },
        nodes: [],
        conds: [],
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, v] of Object.entries(row.item)) {
        const attr = this.objectFull.attributes.find(a => a.key === key)
        if (attr) {
          const gr = this.groupby.groups.find(g => g.isActive && g.entity && g.entity.value === attr.entityid && g.oper && g.oper.sid === 'sum')
          if (gr == null) {
            console.log(key, v)
            if (attr.datatype === 'datetime') {
              console.log('datetime')
              if (this.groupby.groups.some(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'day')) {
                const cond = {
                  isactive: true,
                  oper: { sid: 'inday' },
                  args: [
                    { type: { sid: 'entity' }, value: attr.entityid },
                    { type: { sid: 'value' }, value: v.value },
                  ],
                }
                drillNode.conds.push(cond)
              } else if (this.groupby.groups.some(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'month')) {
                const cond = {
                  isactive: true,
                  oper: { sid: 'inmonth' },
                  args: [
                    { type: { sid: 'entity' }, value: attr.entityid },
                    { type: { sid: 'value' }, value: v.value },
                  ],
                }
                drillNode.conds.push(cond)
              } else if (this.groupby.groups.some(g => g.isActive && g.entity.value === attr.entityid && g.oper.sid === 'year')) {
                const cond = {
                  isactive: true,
                  oper: { sid: 'inyear' },
                  args: [
                    { type: { sid: 'entity' }, value: attr.entityid },
                    { type: { sid: 'value' }, value: v.value },
                  ],
                }
                drillNode.conds.push(cond)
              } else {
                const cond = {
                  isactive: true,
                  oper: { sid: 'equal' },
                  args: [
                    { type: { sid: 'entity' }, value: this.objectFull.attributes.find(a => a.key === key).entityid },
                    { type: { sid: 'value' }, value: v.value },
                  ],
                }
                drillNode.conds.push(cond)
              }
            } else {
              const cond = {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entity' }, value: this.objectFull.attributes.find(a => a.key === key).entityid },
                  { type: { sid: 'value' }, value: v.value },
                ],
              }
              drillNode.conds.push(cond)
            }
          }
        } else {
          console.log('attr not found', key, v)
        }
      }
      flt.node.nodes.push(drillNode)
      if (this.filter && this.filter.node) flt.node.nodes.push(this.filter.node)
      flt.node.nodes.push(this.userFilter.node)
      return flt
    },
    onShowRecords(row) {
      console.log('onShowRecords', row)
      const flt = this.getRowDrillFilter(row)
      const detailsDrill = {
        groupby: store.getters['cubus-store/BLANK_GROUPBY'],
        filter: flt,
      }
      this.$set(row.item, '_drill', detailsDrill)
      this.$set(row.item, '_detailsMode', 'drill')
      // eslint-disable-next-line no-underscore-dangle
      if (row.item._showDetails === undefined) {
        this.$set(row.item, '_showDetails', true)
      } else {
        // eslint-disable-next-line no-underscore-dangle,no-param-reassign
        row.item._showDetails = true
      }
    },
    onSelectTab(tab) {
      console.log('onSelectTab', tab)
      if (this.activeTab === tab) {
        this.activeTab = null
      } else {
        this.activeTab = tab
        if (tab === 'search') {
          console.log('search tab')
          this.$nextTick(() => {
            this.$refs[`search_${this.uuid}`].focus()
          })
        }
      }
    },
    onLoadGroupbyOperands(opers) {
      this.groupbyOpers = opers
    },
    groupbyOper(sid) {
      if (this.groupbyOpers) {
        return this.groupbyOpers.find(el => el.sid === sid)
      }
      return null
    },
    initSimpleFilter() {},
    consFilter() {
      return {
        node: {
          isactive: true,
          isexpanded: false,
          name: 'Root Node',
          oper: { id: 1, sid: 'and', name: 'AND' },
          nodes: [
            this.filter.node,
            this.userFilter.node,
          ],
          conds: [],
        },
      }
    },
    isSpinner() {
      return this.loadingStatus === 'loading' || this.loadingStatus === 'error' || this.loadingStatus === 'message'
    },
    valueTitle(val) {
      // console.log('val', val)
      return val ? val.toString().slice(0, 100) : ''
    },
    tdStyle() {
      return this.mode === 'sheet'
        ? {
          maxHeight: '50px', overflow: 'hidden', height: '100%', width: '100%',
        }
        : { height: '100%', display: 'flex', alignItems: 'flex-start' }
    },
    onChangeCell(field, record) {
      console.log('onChangeCell', field, record)
      const cell = record[field.key]
      this.$set(cell, 'status', 'changed')
      const c = {
        cell,
        attribute: { entityid: field.entityid },
      }
      this.changeRecord(record, c)
    },
    changeRecord(record, cell) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'changerecord',
          param: {
            withsave: true,
            object: { id: this.objectFull.object.id },
            record,
            cell,
          },
        },
      })
        .then(response => {
          console.log('refresh record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadRefresh(response.data.thread, record)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadRefresh(thread, record) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadRefresh response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterRefresh(record, response.data.thread.result.record)
          } else {
            this.delayRefresh(thread, record)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delayRefresh(thread, record) { setTimeout(() => this.threadRefresh(thread, record), 500) },
    afterRefresh(record, newRecord) {
      // console.log('afterRefresh', record, newRecord)
      for (const key in record) {
        if (record.hasOwnProperty(key) && newRecord.hasOwnProperty(key)) {
          if (record[key].value !== newRecord[key].value) {
            this.$set(record[key], 'status', 'changed')
          }
          record[key].value = newRecord[key].value
          record[key].title = newRecord[key].title
        }
      }
      // this.saveRecord(record)
    },
    onClickChild(row, child) {
      console.log('click child', row, child)
      this.$set(row.item, '_child', child)
      this.$set(row.item, '_childFilter', this.childFilter(row))
      this.$set(row.item, '_detailsMode', 'child')
      if (row.item._showDetails === undefined) {
        this.$set(row.item, '_showDetails', true)
      } else {
        row.item._showDetails = true
      }
    },
    childFilter(row) {
      return {
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'ownersid' },
                { type: { sid: 'value' }, value: row.item.sid.value },
              ],
            },
          ],
        },
      }
    },
    onDuplicate(row) {
      console.log('onDuplicate', row)
      this.clearNew()
      this.newRecord = JSON.parse(JSON.stringify(row.item))
      this.newRecord.id.value = null
      this.newRecord.id.title = null
      if (this.newRecord.sid) {
        this.newRecord.sid.value = null
        this.newRecord.sid.title = null
      }
      if (this.newRecord.ordernum) {
        this.newRecord.ordernum.value = null
        this.newRecord.ordernum.title = null
      }
      this.isNew = true
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  margin-top: 0rem;
}
.cbs-dropdown /deep/ .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.cbstbl {
  vertical-align: top;
}
</style>
