import { scrollIntoViewIfNeeded } from '@/@core/utils/utils'

/* eslint-disable no-restricted-syntax */
function tableToMatrix(table) {
  const { rows } = table
  const matrix = []
  const matrixValues = []
  let maxCols = 0

  for (const row of rows) {
    let colCount = 0
    for (const cell of row.cells) {
      colCount += cell.colSpan || 1
    }
    maxCols = Math.max(maxCols, colCount)
  }

  for (let i = 0; i < rows.length; i += 1) {
    matrix[i] = new Array(maxCols).fill(null)
    matrixValues[i] = new Array(maxCols).fill(null)
  }

  for (let row = 0; row < rows.length; row += 1) {
    let col = 0

    for (const cell of rows[row].cells) {
      while (matrix[row][col] !== null) {
        col += 1
      }

      const rowspan = cell.rowSpan || 1
      const colspan = cell.colSpan || 1

      for (let i = 0; i < rowspan; i += 1) {
        for (let j = 0; j < colspan; j += 1) {
          matrix[row + i][col + j] = cell
          matrixValues[row + i][col + j] = cell.textContent
        }
      }

      col += colspan
    }
  }

  return matrix
}

export function selectAllText() {
  setTimeout(() => {
    const el = document.activeElement
    if (!el || !el.isContentEditable) return

    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(el)
    selection.removeAllRanges()
    selection.addRange(range)
    scrollIntoViewIfNeeded(el)
  }, 100)
}

export default function navigateToNextEditableCell(noNextCell, event) {
  // accept: Enter, Tab
  if (event.key !== 'Enter' && event.key !== 'Tab') return false
  const shiftTab = event.shiftKey && event.key === 'Tab'

  event.preventDefault()
  const el = event.target
  if (!el) return false

  const td = el.closest('td')
  const tr = td.closest('tr')
  const tbody = tr.closest('tbody')
  const matrix = tableToMatrix(tbody)
  if (matrix.length === 0) return false

  let searchStarted = false

  if (shiftTab) {
    for (let j = matrix[0].length - 1; j >= 0; j -= 1) {
      for (let i = matrix.length - 1; i >= 0; i -= 1) {
        const tdi = matrix[i][j]
        if (td === matrix[i][j]) {
          searchStarted = true
        } else if (searchStarted && tdi) {
          const nextEl = tdi.querySelector('.activator')
          if (nextEl) {
            nextEl.click()
            selectAllText()
            return true
          }
        }
      }
    }
  } else {
    for (let j = 0; j < matrix[0].length; j += 1) {
      for (let i = 0; i < matrix.length; i += 1) {
        const tdi = matrix[i][j]
        if (td === matrix[i][j]) {
          searchStarted = true
        } else if (searchStarted && tdi) {
          const nextEl = tdi.querySelector('.activator')
          if (nextEl) {
            nextEl.click()
            selectAllText()
            return true
          }
        }
      }
    }
  }

  const nextTbody = tbody[shiftTab ? 'previousElementSibling' : 'nextElementSibling']
  if (!nextTbody) return false

  const nextMatrix = tableToMatrix(nextTbody)
  if (nextMatrix.length === 0) return false

  if (shiftTab) {
    for (let j = nextMatrix[0].length - 1; j >= 0; j -= 1) {
      for (let i = nextMatrix.length - 1; i >= 0; i -= 1) {
        const tdi = nextMatrix[i][j]
        if (tdi) {
          const nextEl = tdi.querySelector('.activator')
          if (nextEl) {
            nextEl.click()
            selectAllText()
            return true
          }
        }
      }
    }
  } else {
    for (let j = 0; j < nextMatrix[0].length; j += 1) {
      for (let i = 0; i < nextMatrix.length; i += 1) {
        const tdi = nextMatrix[i][j]
        if (tdi) {
          const nextEl = tdi.querySelector('.activator')
          if (nextEl) {
            nextEl.click()
            selectAllText()
            return true
          }
        }
      }
    }
  }

  return false
}
