<template>
  <div>
    <div>
      <feather-icon v-if="!noIcon && condition" :class="condition.isactive ? 'text-success' : ''" icon="FilterIcon"
                    style="cursor: pointer;" @click="expand()"/>
      <div v-if="expanded" style="text-align: left;"
           class="cbs-dropdown-menu input-container"
           @mouseover="isMouseOn = true"
           @mouseout="onMouseOut"
      >
        <cbs-reference-picker v-if="column.datatype === 'ref' || column.datatype === 'refsid'" :field="refField"
                              :record="refRec" :prop-ref="reference"
                              no-refresh no-link
                              @updateCell="updateRef()"/>
        <b-input-group v-else class="input-group-merge">
          <b-form-input v-model="filterValue"/>
          <b-input-group-append is-text>
            <feather-icon icon="XIcon" class="cursor-pointer" @click="filterValue = null"/>
          </b-input-group-append>
        </b-input-group>
        <b-button style="margin-top: 0.5rem; margin-right: 0.5rem;" variant="outline-danger" size="sm" @click="clear()">
          <feather-icon icon="XIcon"/>&nbsp;Очистить
        </b-button>
        <b-button style="margin-top: 0.5rem;" variant="outline-primary" size="sm" @click="apply()">
          <feather-icon icon="PlayIcon"/>&nbsp;Применить
        </b-button>
        <cbs-debug v-if="false && session.user.sid === 'root'" :context="this"/>
      </div>
    </div>
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BButton, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import CbsReferencePicker from '@/cubus/components/reference/CbsReferencePicker.vue'

export default {
  name: 'CbsBudreqFilter',
  components: {
    CbsReferencePicker,
    BInputGroup,
    BInputGroupAppend,
    CbsDebug,
    BFormInput,
    BButton,
  },
  props: {
    column: {
      type: Object,
      default: null,
    },
    condition: {
      type: Object,
      default: null,
    },
    reference: {
      type: Array,
      default: null,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['apply'],
  setup() {
    const session = useCubus.getSession()
    return {
      session,
    }
  },
  data() {
    return {
      expanded: false,
      isMouseOn: false,
      filterValue: null,
      refRec: {
        refValue: {
          sid: null,
          title: null,
          value: null,
        },
      },
      refField: {
        key: 'refValue',
      },
    }
  },
  methods: {
    onMouseOut() {
      // this.expanded = false
      this.isMouseOn = false
    },
    apply() {
      if (this.column.datatype === 'id') {
        this.filterValue = Number(this.filterValue)
        if (this.filterValue === 0) this.filterValue = null
      } else if (this.column.datatype === 'string') {
        if (this.filterValue === '') this.filterValue = null
      }
      if (this.filterValue === null || this.filterValue === '') {
        this.condition.isactive = false
      } else {
        this.condition.isactive = true
        this.condition.args[1].value = this.filterValue
      }
      this.expanded = false
      this.$emit('apply')
    },
    clear() {
      this.filterValue = null
      this.refRec.refValue = {value: null, title: null, sid: null}
      this.apply()
    },
    updateRef() {
      if (this.refRec.refValue.value === 0 || this.refRec.refValue.value === null) {
        this.filterValue = null
      }
      this.filterValue = this.refRec.refValue.value
      this.apply()
    },
    handleEnterKey(event) {
      if (event.key === 'Enter') {
        this.apply()
      }
    },
    handleEscKey(event) {
      if (event.key === 'Escape') {
        this.collapse()
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.collapse()
      }
    },
    expand() {
      this.expanded = !this.expanded
      if (this.expanded) {
        setTimeout(() => {
          const el = document.querySelector('.input-container')
          if (el) {
            const input = el.querySelector('input')
            if (input) {
              input.focus()
              input.removeEventListener('keydown', this.handleEnterKey)
              input.addEventListener('keydown', this.handleEnterKey)
            }
          }
          document.addEventListener('keydown', this.handleEscKey)
          document.addEventListener('click', this.handleClickOutside)
        }, 100)
      }
    },
    collapse() {
      this.expanded = false
      document.removeEventListener('keydown', this.handleEscKey)
      document.removeEventListener('click', this.handleClickOutside)
    },
  },
}
</script>

<style scoped>
.cbs-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0.3rem;
  margin: 0;
  width: 20vw;
  z-index: 99;
  max-height: 30vh;
  border: solid 1px #ccc;
  border-radius: 0.3rem;
}
</style>
