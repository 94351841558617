<template>
  <div>
    <b-dropdown
      :text="argument.type.name"
      variant="flat-primary"
      size="sm"
    >
      <b-dropdown-item v-for="(type, index) in types" :key="'argtype' + index" @click="selectType(type.sid)">
        {{ type.name }}
      </b-dropdown-item>
    </b-dropdown>
    <div class="h-100">

      <v-select v-if="argument.type.sid === 'entity'" class="h-100"
                v-model="argument.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="fields"
                :filter-by="filterBy"
                @input="onChange"
      />

      <v-select v-else-if="argument.type.sid === 'paramentity'"
                v-model="argument.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="paramFields"
                :filter-by="filterBy"
                @input="onChange"
      />

      <div v-else-if="argument.type.sid === 'sourceentity'">
        <cbs-data-reference entitysid="entity" @load="onLoadEntity" hide />
        <v-select v-if="entities"
                  v-model="argument.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entities"
                  :filter-by="filterBy"
                  @input="onChange"
        />
      </div>

      <div v-else-if="argument.type.sid === 'formula'">
        <cbs-reference-picker-sid entitysid="formula" :value-container="argument" value-name="value" />
      </div>

      <div v-else-if="argument.type.sid === 'function'">
        <cbs-reference-picker-sid entitysid="function" :value-container="argument" value-name="value" />
      </div>

      <div v-else-if="argument.type.sid === 'scalar'">
        <cbs-reference-picker-sid entitysid="scalar" :value-container="argument" value-name="value" />
      </div>

      <b-form-checkbox v-else-if="argument.datatype === 'bit'"
                       v-model="argument.value"
                       @change="onChange"
      />

      <div v-else-if="argument.entitysid === 'parent'">
        <v-select v-model="argument.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="getParentRef()"
                  @input="onInputVSelect($event,index)"
                  :class="selectClass(fld)"
        />
        <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
          {{ fldState(fld).status }}
        </div>
        <b-form-invalid-feedback>
          {{ fldState(fld).status }}
        </b-form-invalid-feedback>
        <div class="invalid-feedback">
          {{ fldState(fld).status }}
        </div>
      </div>

      <v-select v-else-if="argument.datatype === 'ref'"
                v-model="argument.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="getEntRef()"
                :filter-by="filterBy"
      />
      <v-select v-else-if="argument.datatype === 'refsid'"
                v-model="argument.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="getEntRef()"
                :filter-by="filterBy"
      />

      <span v-else-if="argument.datatype === 'datetime'">
        <b-input-group class="mb-1">
          <b-form-input
            v-model="argument.value"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            show-decade-nav
          />
          <b-input-group-append>
            <b-form-datepicker
              v-model="argument.value"
              show-decade-nav
              button-only
              button-variant="outline-primary"
              right
              size="sm"
              locale="en-US"
              aria-controls="example-input"
              @context="onContext"
            />
          </b-input-group-append>
        </b-input-group>
      </span>

      <b-form-input v-else placeholder="Enter value" v-model="argument.value" @change="onChange" />

      <cbs-data-attribute v-if="argument.attributeid" hide :attribute-id="argument.attributeid" @load="onLoadAttribute" />
      <cbs-data-reference v-if="attribute" hide :entityid="attribute.entity.id" @load="onLoadRef" />

      <cbs-debug v-if="false && isRoot" :context="this" />

    </div>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormCheckbox, BFormDatepicker, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import store from '@/store/index'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsReferencePickerSid from '@/cubus/components/reference/CbsReferencePickerSid.vue'
import CbsDataAttribute from '@/cubus/components/attribute/CbsDataAttribute.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsConditionArgument',
  components: {
    CbsDebug,
    CbsDataAttribute,
    CbsReferencePickerSid,
    CbsDataReference,
    BFormInput,
    BDropdownItem,
    BDropdown,
    vSelect,
    BFormCheckbox,
    BInputGroupAppend,
    BFormDatepicker,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    argument: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    paramFields: {
      type: Array,
      default: () => [],
    },
    datatype: {
      type: String,
      default: null,
    },
  },
  emits: [
    'selecttype',
    'change',
  ],
  setup() {
    return {
      isRoot: useCubus.isRoot(),
    }
  },
  data() {
    return {
      types: [
        { id: 1, sid: 'entity', name: 'Entity' },
        { id: 2, sid: 'value', name: 'Value' },
      ],
      refs: [],
      entities: null,
      attribute: null,
      isDebug: false,
      ref: null,
    }
  },
  watch: {
    'argument.value': {
      handler(newVal, oldVal) {
        this.$emit('change')
      },
      immediate: true,
    },
  },
  created() {
    this.initTypes()
  },
  methods: {
    initTypes() {
      if (store.getters['cubus-store/ARGUMENT_TYPES']) {
        this.types = store.getters['cubus-store/ARGUMENT_TYPES']
      } else this.loadTypes()
    },
    loadTypes() {
      useJwt.query({
        query: {
          method: 'argumenttypes',
          param: {},
        },
      })
        .then(response => {
          // console.log('response', response)
          if (response.data.thread) {
            this.threadTypes(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.dataerror)
          } else useCubus.toastError(this, 'Thread not provided.')
        })
        .catch(error => {
          console.log('error', error)
          useCubus.toastError(this.error)
        })
    },
    delayTypes(thread) { setTimeout(() => this.threadTypes(thread), 1000) },
    threadTypes(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          // console.log('response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'done') {
            this.types = response.data.thread.result.types
            store.commit('cubus-store/SET_ARGUMENT_TYPES', this.types)
          } else if (response.data.thread.error) {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayTypes(thread)
          }
        })
        .catch(error => {
          console.log('error', error)
          useCubus.toastError(this, error)
        })
    },
    selectType(sid) {
      this.argument.type = this.types.find(t => t.sid === sid)
      this.argument.value = null
      this.$emit('selecttype', { index: this.index, sid })
    },
    onChange() {
      // console.log('value change', this.argument.value)
      this.$emit('change', { index: this.index, value: this.argument.value })
    },
    getEntRef() {
      if (this.ref) { return this.ref }
      if (!this.refs[this.argument.entityid]) {
        this.loadRef(this.argument.entityid)
      }
      return this.refs[this.argument.entityid]
    },
    /* loadEntRef(entityid) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'entref',
          param: { entityid },
        },
      })
        .then(response => {
          // console.log('entref response', response)
          if (response.data.thread) this.delayEntref(entityid, response.data.thread)
          if (response.data.error) useCubus.toastError(this, response.data.error)
        })
        .catch(error => {
          console.log('entref error', error)
          useCubus.toastError(this, error)
        })
    },
    delayEntref(entityid, threadname) {
      setTimeout(() => this.loadThreadEntref(entityid, threadname), 500)
    },
    loadThreadEntref(entityid, threadname) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname },
        },
      })
        .then(response => {
          if (response.data.thread) {
            if (response.data.thread.status === 'done') {
              console.log('loadThreadEntref', response)
              this.$set(this.refs, entityid, response.data.thread.result.ref)
            } else {
              this.delayEntref(entityid, threadname)
            }
          }
        })
        .catch(error => {
          console.log('loadThreadEntref error', error)
          useCubus.toastError(this, error)
        })
    }, */
    getParentRef() {
      if (!this.argument.parententityid) {
        // this.loadEntRef(this.argument.parententityid)
        this.loadRef(this.argument.parententityid)
      }
      return this.refs[this.argument.parententityid]
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    filterBy(option, label, search) {
      const pattern = `^${search.replace(new RegExp(' ', 'gi'), '.*').replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onLoadEntity(ref) {
      this.entities = ref
    },
    onLoadAttribute(attr) {
      this.attribute = attr
    },
    onLoadRef(ref) {
      this.ref = ref
    },

    async loadRef(entityid) {
      const ref = await useCubus.loadRef(this, { entityid })
      console.log('loadRef', entityid, ref)
      const rf = ref.map(r => ({ sid: r.sid, value: r.value, title: r.title && r.title !== '' ? r.title : `(${r.value})` }))
      this.$set(this.refs, entityid, rf)
    },
  },
}
</script>

<style scoped>

</style>
