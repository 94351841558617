<template>
  <div>
    <div style="margin-top: 10px;">
      <b-button-group>
        <b-button size="sm" variant="flat-primary" @click="step.isExpanded=!step.isExpanded"><feather-icon :icon="step.isExpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" /></b-button>
        <b-button size="sm" :disabled="!canUp" :variant="canUp ? 'flat-primary' : 'flat-secondary'" @click="stepUp()"><feather-icon icon="ArrowUpIcon" /></b-button>
        <b-button size="sm" :disabled="!canDown" :variant="canDown ? 'flat-primary' : 'flat-secondary'" @click="stepDown()"><feather-icon icon="ArrowDownIcon" /></b-button>
      </b-button-group>
      <b-button-group>
        <div style="margin-left:10px;">
          <b-form-checkbox v-model="step.isActive" name="check-button" switch inline class="custom-control-success"/>
        </div>
      </b-button-group>
      <b-button-group>
        <span v-if="!isEditName">{{step.name}}</span>
        <b-form-input v-else v-model="step.name" />
        <b-button size="sm" variant="flat-primary" @click="isEditName = !isEditName">
          <feather-icon icon="Edit3Icon" />
        </b-button>
        <b-button size="sm" variant="flat-danger" @click="onDelete">
          <feather-icon icon="TrashIcon" /> Delete
        </b-button>
      </b-button-group>
    </div>
    <cbs-collapse :trigger="step.isExpanded">
      <div style="margin-top: 5px; margin-bottom: 15px;">
        <small class="text-secondary">Operand:</small>
        <v-select v-if="opers"
                  v-model="step.operand"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="opers"
                  size="sm"
                  placeholder="Select Operand..."
                  @input="onSelectOperand($event)"
        >
          <template slot="option" slot-scope="item">
            {{ item.title }}
            <small class="text-muted"><i>({{ item.value }})</i></small>
          </template>
        </v-select>
        <div v-if="step.operand">
          <div v-if="step.operand.sid === 'query'">
            <b-row class="mt-1">
              <b-col>
                <small class="text-secondary">Object:</small>
                <v-select v-if="refObject"
                          v-model="step.object"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="refObject"
                          size="sm"
                          placeholder="Select Object..."
                />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="6" xl="4">
                <b-form-group>
                  <small class="text-secondary">Sheet name:</small>
                  <b-form-input v-model="step.sheetname" placeholder="Sheet name" />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group>
                  <small class="text-secondary">Row index:</small>
                  <b-form-input v-model="step.rowindex" placeholder="Row index" />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group>
                  <small class="text-secondary">Column index:</small>
                  <b-form-input v-model="step.colindex" placeholder="Column index" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col>
                <small class="text-secondary">Cell Styles:</small>
                <v-select v-model="step.styletype"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="styletypes"
                          size="sm"
                          placeholder="Select Style type..."
                />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <small class="text-secondary">Query filter:</small>
                <div style="border:solid #cccccc 1px; border-radius: 5px; padding-left:10px; padding-right: 10px;padding-bottom:10px;">
                  <cbs-filter :filter="step.queryFilter" :fields="queryFields()" :param-fields="paramFields()" />
                </div>
              </b-col>
            </b-row>
            <cbs-data-reference entitysid="object" @load="onLoadRefObject" hide />
            <cbs-data-object v-if="step.object && step.object.value" hide :objectid="step.object.value" @load="onLoadQueryObject" />
          </div>
          <div v-else-if="step.operand.sid === 'formula'">
            <b-row class="mt-1">
              <b-col>
                <small class="text-secondary">Formula:</small>
                <cbs-reference-picker-sid :value-container="step" value-name="formula" entitysid="formula" placeholder="Select Formula..." />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="6" xl="4">
                <b-form-group>
                  <small class="text-secondary">Sheet name:</small>
                  <b-form-input v-model="step.sheetname" placeholder="Sheet name" />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group>
                  <small class="text-secondary">Row index:</small>
                  <b-form-input v-model="step.rowindex" placeholder="Row index" />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group>
                  <small class="text-secondary">Column index:</small>
                  <b-form-input v-model="step.colindex" placeholder="Column index" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-else-if="step.operand.sid === 'hidesheet'">
            <b-row class="mt-1">
              <b-col>
                <small class="text-secondary">Sheet Name:</small>
                <b-form-input v-model="step.sheetname" placeholder="Value" />
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </cbs-collapse>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import vSelect from 'vue-select'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsFilter from '@/cubus/components/filter/CbsFilter.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsReferencePickerSid from '@/cubus/components/reference/CbsReferencePickerSid.vue'

export default {
  name: 'CbsReportConfigStep',
  components: {
    CbsReferencePickerSid,
    CbsDebug,
    CbsDataObject,
    CbsCollapse,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    CbsDataReference,
    CbsFilter,
  },
  props: {
    step: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    opers: {
      type: Array,
      default: null,
    },
    canUp: {
      type: Boolean,
      default: false,
    },
    canDown: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
    },
    paramRecord: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'moveUp',
    'moveDown',
    'delete',
  ],
  data() {
    return {
      isEditName: false,
      refObject: null,
      queryObject: null,
      refFormula: null,
      styletypes: [
        {
          title: 'No Styles',
          value: 'nostyle',
        },
        {
          title: 'First Row',
          value: 'firstrow',
        },
      ],
    }
  },
  methods: {
    stepUp() {
      // console.log('step up 1')
      this.$emit('moveup', this.index)
    },
    stepDown() {
      // console.log('step down 1')
      this.$emit('movedown', this.index)
    },
    onLoadRefObject(ref) {
      this.refObject = ref
    },
    onLoadRefFormula(ref) {
      this.refFormula = ref
    },
    onLoadQueryObject(obj) {
      this.queryObject = obj
    },
    queryFields() {
      if (this.queryObject) {
        return this.queryObject.attributes.map(a => useCubus.attributeToField(a))
      }
      return []
    },
    paramFields() {
      if (this.object) {
        return this.object.attributes.map(a => useCubus.attributeToField(a))
      }
      return []
    },
    onDelete() {
      this.$emit('delete')
    },
    onSelectOperand(v) {
      console.log('select operand', v)
      if (!v) {
        // this.step.operand = { value: null, sid: null, title: null }
        this.step.isActive = false
        if (this.step.formula) this.step.formula = null
      } else if (v.sid === 'formula') {
        this.$set(this.step, 'formula', null)
      }
    },
  },
}
</script>

<style scoped>

</style>
